import {Badge, Card, Col, Container, Row} from "react-bootstrap";
import {useContext} from "react";
import {AuthContext} from "../../context/Context";
import SoftBadge from "../../components/common/SoftBadge";

const SyncOverview = ({
                          ...otherProps
}) => {
    const { user } = useContext(AuthContext);

    return (
        <Row className="pt-3">
            <Col xs={12}>
                <Card>
                    <Card.Body>
                        <SoftBadge pill
                               bg="info"
                               text="dark"
                               className="fs-1 me-2">No Automated Synchronization Configured</SoftBadge>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
};

export default SyncOverview;