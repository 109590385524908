import React, {useEffect} from 'react';
import { BrowserRouter } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.min.css';
import Layout from './layouts/Layout';
import {processLogin, STORAGE_KEY_TOKEN} from "./helpers/utils";

const PRODUCTION_DOMAINS = [
    "lemon-glacier-00dd59903.westeurope.3.azurestaticapps.net",
    "console.myndrive.com",
    "console.corectic.com"
];
export const API_URL = (() => {
    if (PRODUCTION_DOMAINS.includes(window.location.hostname)) {
        return "https://corectic.azure-api.net/core-ui-services";
    } else {
        return "https://corectic.azure-api.net/dev-core-ui-services";
    }
})();

export const CORE_API_URL = (() => {
    if (PRODUCTION_DOMAINS.includes(window.location.hostname)) {
        return "https://corectic.azure-api.net/dev-core-services";
    } else {
        return "https://corectic.azure-api.net/dev-core-services";
    }
})();

const App = () => {
    useEffect(() => {

        if (["console.myndrive.com", "localhost"].includes(window.location.hostname)) {
            console.log("xxx:", window.location.hostname);
            document?.querySelector('link[rel="shortcut icon"]')?.setAttribute?.('href', `${process.env.PUBLIC_URL}/myndrive_favicon.ico`);
        }
    }, []);

    return (
        <BrowserRouter basename={process.env.PUBLIC_URL}>
            <Layout/>
        </BrowserRouter>
    );
};

export default App;
