import { Button, ButtonGroup, Card, Col, Container, FloatingLabel, Form, InputGroup, Nav, Navbar, OverlayTrigger, Row, Spinner, Table, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon as Icons } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from "react";
import { BriHorizontalStack } from "../components/BriHorizontalStack";
import axios from "axios";
import { API_URL } from "App";
import { AuthContext } from "../../context/Context";

const CorecticToggleButton = ({
    corecticVariant,
    value,
    ...otherProps
}) => {

    return (
        <div className={`bri-bg-core-${corecticVariant?.toLowerCase()} p-2 ${value ? "" : "bg-opacity-10"}`}>
            <Button {...otherProps} size="sm"
                variant={otherProps.variant || "secondary"} >{corecticVariant?.toUpperCase()}</Button>
        </div>

    );

}

const PatternValueFieldConfiguration = ({
    mapping,
    label,
    placeholder,
    ...otherProps
}) => {
    (mapping || []).forEach((item, index) => {
        item["_$tmpOrderPos"] = item["_$tmpOrderPos"] || `${Date.now()}_${index}`;
    });
    const [mappingWrapper, setMappingWrapper] = useState({ item: (mapping || []) });

    // console.log('DBG BBB', mappingWrapper);

    return (
        <>
            {mappingWrapper.item.map((entry, index) => {
                return (
                    <Row key={`pattern-value-${entry["_$tmpOrderPos"]}`}
                        className="m-2 border-2 border-bottom">
                        <Col xs={4}>
                            <FloatingLabel controlId={`pattern-value-${entry["_$tmpOrderPos"]}-pattern`}
                                label={label}>
                                <Form.Control type="text" size="sm"
                                    value={entry.pattern}
                                    placeholder={placeholder || label}
                                    onChange={(event) => {
                                        entry.pattern = event.target.value;
                                        setMappingWrapper({ item: mappingWrapper.item });
                                    }} />
                            </FloatingLabel>
                        </Col>
                        <Col xs={7}>
                            <ButtonGroup className="d-flex flex-column w-100">
                                <CorecticToggleButton
                                    corecticVariant="C"
                                    className={`w-100 ${entry.values.includes(1) ? 'bri-core-c' : 'btn-secondary'}`}
                                    value={entry.values.includes(1)}
                                    onClick={(event) => {
                                        if (entry.values.includes(1)) {
                                            entry.values.splice(entry.values.indexOf(1), 1);
                                        } else {
                                            entry.values.push(1);
                                        }
                                        setMappingWrapper({ item: mappingWrapper.item });
                                    }}
                                />
                                <CorecticToggleButton corecticVariant="O"
                                    className={`w-100 ${entry.values.includes(2) ? 'bri-core-o' : 'btn-secondary'}`}
                                    value={entry.values.includes(2)}
                                    onClick={(event) => {
                                        if (entry.values.includes(2)) {
                                            entry.values.splice(entry.values.indexOf(2), 1);
                                        } else {
                                            entry.values.push(2);
                                        }
                                        setMappingWrapper({ item: mappingWrapper.item });
                                    }} />
                                <CorecticToggleButton corecticVariant="R"
                                    className={`w-100 ${entry.values.includes(3) ? 'bri-core-r' : 'btn-secondary'}`}
                                    value={entry.values.includes(3)}
                                    onClick={(event) => {
                                        if (entry.values.includes(3)) {
                                            entry.values.splice(entry.values.indexOf(3), 1);
                                        } else {
                                            entry.values.push(3);
                                        }
                                        setMappingWrapper({ item: mappingWrapper.item });
                                    }} />
                                <CorecticToggleButton corecticVariant="E"
                                    className={`w-100 ${entry.values.includes(4) ? 'bri-core-e' : 'btn-secondary'}`}
                                    value={entry.values.includes(4)}
                                    onClick={(event) => {
                                        if (entry.values.includes(4)) {
                                            entry.values.splice(entry.values.indexOf(4), 1);
                                        } else {
                                            entry.values.push(4);
                                        }
                                        setMappingWrapper({ item: mappingWrapper.item });
                                    }} />
                            </ButtonGroup>
                        </Col>
                        <Col xs={1} className="d-flex align-items-center justify-content-center">
                            <Button variant="secondary" size="sm"
                                className="my-auto"
                                onClick={(event) => {
                                    mappingWrapper.item.splice(index, 1)
                                    setMappingWrapper({ item: mappingWrapper.item });
                                }}><Icons icon="trash" /></Button>
                        </Col>
                    </Row>
                );
            })}
            <Row className="m-2">
                <Col xs={12}
                    className="d-flex align-items-center justify-content-center">
                    <Button variant="success" size="sm"
                        className="my-auto w-50"
                        onClick={(event) => {
                            event.preventDefault();
                            mappingWrapper.item.push({
                                "_$tmpOrderPos": `${Date.now()}_new${Math.random().toString().substr(2, 6)}`,
                                "pattern": undefined,
                                "values": []
                            });
                            setMappingWrapper({ item: mappingWrapper.item });
                        }}>Add New</Button>

                </Col>
            </Row>
        </>
    );
};


const RightClosedIntervalFieldConfiguration = ({
    mapping,
    ...otherProps
}) => {
    (mapping || []).forEach((item, index) => {
        item["_$tmpOrderPos"] = item["_$tmpOrderPos"] || `${Date.now()}_${index}`;
    });
    const [mappingWrapper, setMappingWrapper] = useState({ item: (mapping || []) });

    return (
        <BriHorizontalStack className="">
            {mappingWrapper.item.map((entry, index) => {
                console.log('DBG CCC', entry)
                return (
                    <BriHorizontalStack.Item key={`right-interval-${entry["_$tmpOrderPos"]}`}>
                        <Card className={"w-100 border-0 " + (index < (mappingWrapper.item.length - 1) ? "bri-right-interval-item" : "")}>
                            <Card.Body className="">
                                {(index < (mappingWrapper.item.length - 1))
                                    ? <InputGroup className="p-2">
                                        <Form.Floating className=""
                                            style={{
                                                maxWidth: "8.5ch"
                                            }}>
                                            <Form.Control type="number" size="sm"
                                                placeholder="Percentile"
                                                min={0}
                                                max={100}
                                                value={entry.percentile || ""}
                                                onChange={(event) => {
                                                    entry.percentile = parseFloat(event.target.value);
                                                    setMappingWrapper({ item: mappingWrapper.item });
                                                }} />
                                            <label>x<sup>th</sup></label>
                                        </Form.Floating>
                                        <InputGroup.Text>:</InputGroup.Text>
                                        <Form.Floating className=""
                                            style={{
                                                maxWidth: "14.5ch"
                                            }}>
                                            <Form.Control type="number" size="sm"
                                                placeholder="Threshold"
                                                value={entry.endpoint || ""}
                                                onChange={(event) => {
                                                    entry.endpoint = parseFloat(event.target.value);
                                                    setMappingWrapper({ item: mappingWrapper.item });
                                                }} />
                                            <label>Threshold</label>
                                        </Form.Floating>
                                        <Button variant="secondary"
                                            onClick={(event) => {
                                                event.preventDefault();
                                                mappingWrapper.item.splice(index, 1);
                                                setMappingWrapper({ item: mappingWrapper.item });
                                            }}><Icons icon="trash" /></Button>
                                    </InputGroup>
                                    : <InputGroup className="p-2 d-flex justify-content-end">
                                        <Button variant="success" size="sm"
                                            className="w-100"
                                            style={{
                                                height: "calc(3.5rem + 2px)"
                                            }}
                                            onClick={(event) => {
                                                event.preventDefault();
                                                mappingWrapper.item.push({
                                                    "_$tmpOrderPos": `${Date.now()}_new${Math.random().toString().substr(2, 6)}`,
                                                    "endpoint": undefined,
                                                    "percentile": undefined,
                                                    "values": []
                                                });
                                                setMappingWrapper({ item: mappingWrapper.item });

                                            }}>Add New</Button>
                                    </InputGroup>
                                }
                                <ButtonGroup className="d-flex flex-column w-100">
                                    <CorecticToggleButton corecticVariant="C"
                                        className={`w-100 ${entry.values.includes(1) ? 'bri-core-c' : ''}`}
                                        value={entry.values.includes(1)}
                                        onClick={(event) => {
                                            if (entry.values.includes(1)) {
                                                entry.values.splice(entry.values.indexOf(1), 1);
                                            } else {
                                                entry.values.push(1);
                                            }
                                            setMappingWrapper({ item: mappingWrapper.item });
                                        }} />
                                    <CorecticToggleButton corecticVariant="O"
                                        className={`w-100 ${entry.values.includes(2) ? 'bri-core-o' : ''}`}
                                        value={entry.values.includes(2)}
                                        onClick={(event) => {
                                            if (entry.values.includes(2)) {
                                                entry.values.splice(entry.values.indexOf(2), 1);
                                            } else {
                                                entry.values.push(2);
                                            }
                                            setMappingWrapper({ item: mappingWrapper.item });
                                        }} />
                                    <CorecticToggleButton corecticVariant="R"
                                        className={`w-100 ${entry.values.includes(3) ? 'bri-core-r' : ''}`}
                                        value={entry.values.includes(3)}
                                        onClick={(event) => {
                                            if (entry.values.includes(3)) {
                                                entry.values.splice(entry.values.indexOf(3), 1);
                                            } else {
                                                entry.values.push(3);
                                            }
                                            setMappingWrapper({ item: mappingWrapper.item });
                                        }} />
                                    <CorecticToggleButton corecticVariant="E"
                                        className={`w-100 ${entry.values.includes(4) ? 'bri-core-e' : ''}`}
                                        value={entry.values.includes(4)}
                                        onClick={(event) => {
                                            if (entry.values.includes(4)) {
                                                entry.values.splice(entry.values.indexOf(4), 1);
                                            } else {
                                                entry.values.push(4);
                                            }
                                            setMappingWrapper({ item: mappingWrapper.item });
                                        }} />
                                </ButtonGroup>
                            </Card.Body>
                        </Card>
                    </BriHorizontalStack.Item>
                );
            })}
        </BriHorizontalStack>
    );
};


const OverlappingIntervalsFieldConfiguration = ({
    mapping,
    ...otherProps
}) => {
    (mapping || []).forEach((item, index) => {
        item["_$tmpOrderPos"] = item["_$tmpOrderPos"] || `${Date.now()}_${index}`;
    });
    const [mappingWrapper, setMappingWrapper] = useState({ item: (mapping || []) });

    return (
        <>
            {mappingWrapper.item.map((entry, index) => {
                return (
                    <Row key={`overlapping-interval-${entry["_$tmpOrderPos"]}`}
                        className="m-2 border-2 border-bottom">
                        <Col xs={4}>
                            <FloatingLabel controlId={`overlapping-interval-${entry["_$tmpOrderPos"]}-from`}
                                label="From Value"
                                className="pb-2">
                                <Form.Control type="text" size="sm"
                                    value={entry.startpoint == null ? undefined : entry.startpoint}
                                    placeholder="From Value"
                                    onChange={(event) => {
                                        event.preventDefault();
                                        entry.startpoint = parseFloat(event.target.value);
                                        setMappingWrapper({ item: mappingWrapper.item });
                                    }} />
                            </FloatingLabel>
                            <FloatingLabel controlId={`overlapping-interval-${entry["_$tmpOrderPos"]}-to`}
                                label="To Value">
                                <Form.Control type="text" size="sm"
                                    value={entry.endpoint == null ? undefined : entry.endpoint}
                                    placeholder="To Value"
                                    onChange={(event) => {
                                        event.preventDefault();
                                        entry.endpoint = parseFloat(event.target.value);
                                        setMappingWrapper({ item: mappingWrapper.item });
                                    }} />
                            </FloatingLabel>
                        </Col>
                        <Col xs={7}>
                            <ButtonGroup className="d-flex flex-column w-100">
                                <CorecticToggleButton corecticVariant="C"
                                    className={`w-100 ${entry.values.includes(1) ? 'bri-core-c' : ''}`}
                                    value={entry.values.includes(1)}
                                    onClick={(event) => {
                                        if (entry.values.includes(1)) {
                                            entry.values.splice(entry.values.indexOf(1), 1);
                                        } else {
                                            entry.values.push(1);
                                        }
                                        setMappingWrapper({ item: mappingWrapper.item });
                                    }} />
                                <CorecticToggleButton corecticVariant="O"
                                    className={`w-100 ${entry.values.includes(2) ? 'bri-core-o' : ''}`}
                                    value={entry.values.includes(2)}
                                    onClick={(event) => {
                                        if (entry.values.includes(2)) {
                                            entry.values.splice(entry.values.indexOf(2), 1);
                                        } else {
                                            entry.values.push(2);
                                        }
                                        setMappingWrapper({ item: mappingWrapper.item });
                                    }} />
                                <CorecticToggleButton corecticVariant="R"
                                    className={`w-100 ${entry.values.includes(3) ? 'bri-core-r' : ''}`}
                                    value={entry.values.includes(3)}
                                    onClick={(event) => {
                                        if (entry.values.includes(3)) {
                                            entry.values.splice(entry.values.indexOf(3), 1);
                                        } else {
                                            entry.values.push(3);
                                        }
                                        setMappingWrapper({ item: mappingWrapper.item });
                                    }} />
                                <CorecticToggleButton corecticVariant="E"
                                    className={`w-100 ${entry.values.includes(4) ? 'bri-core-e' : ''}`}
                                    value={entry.values.includes(4)}
                                    onClick={(event) => {
                                        if (entry.values.includes(4)) {
                                            entry.values.splice(entry.values.indexOf(4), 1);
                                        } else {
                                            entry.values.push(4);
                                        }
                                        setMappingWrapper({ item: mappingWrapper.item });
                                    }} />
                            </ButtonGroup>
                        </Col>
                        <Col xs={1} className="d-flex align-items-center justify-content-center">
                            <Button variant="secondary"
                                className="my-auto"
                                size="sm"
                                onClick={(event) => {
                                    mappingWrapper.item.splice(index, 1)
                                    setMappingWrapper({ item: mappingWrapper.item });
                                }}><Icons icon="trash" /></Button>
                        </Col>
                    </Row>
                );
            })}
            <Row className="m-2">
                <Col xs={12}
                    className="d-flex align-items-center justify-content-center">
                    <Button variant="outline-success"
                        className="my-auto w-50"
                        onClick={(event) => {
                            mappingWrapper.item.push({
                                "_$tmpOrderPos": `${Date.now()}_new${Math.random().toString().substr(2, 6)}`,
                                "startpoint": undefined,
                                "endpoint": undefined,
                                "values": []
                            });
                            setMappingWrapper({ item: mappingWrapper.item });
                        }}>Add New</Button>

                </Col>
            </Row>
        </>
    );
};


const FieldConfiguration = ({
    config,
    ...otherProps
}) => {
    switch (config?.type) {
        case 0:
            return (
                <></>
            );
        case 1:
            return (
                <PatternValueFieldConfiguration key={config?.code}
                    label="Value"
                    placeholder="Value"
                    mapping={config?.mapping} />
            );
        case 2:
            return (
                <PatternValueFieldConfiguration key={config?.code}
                    label="Regular Expression Pattern"
                    placeholder="Regular Expression Pattern"
                    mapping={config?.mapping} />
            );
        case 3:
            return (
                <RightClosedIntervalFieldConfiguration key={config?.code}
                    mapping={config?.mapping} />
            );
        case 5:
            return (
                <OverlappingIntervalsFieldConfiguration key={config?.code}
                    mapping={config?.mapping} />
            );
        default:
            console.error(`Unknown or unsupported field type: ${config?.type}`);
            return (
                <></>
            );
    }
}

const Configurator = ({
    ...otherProps
}) => {
    const { user } = useContext(AuthContext);

    const [fieldItems, setFieldItems] = useState(null);
    const [currentItemWrapper, setCurrentItemWrapper] = useState({ item: {} });
    const [storingConfiguration, setStoringConfiguration] = useState(false);

    useEffect(() => {
        if (fieldItems == null) {
            (async () => {
                const fieldsResp = await axios.get(
                    `${API_URL}/customer/fields`,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Access-Control-Allow-Credentials': 'true',
                            'Accept': '*/*',
                            'Authorization': `Bearer ${user?.token}`,
                            'X-Api-Key': user?.clientKey
                        },
                        validateStatus: () => true
                    }
                );
                if ((200 <= fieldsResp.status) && (fieldsResp.status < 300)) {
                    setFieldItems(fieldsResp?.data?.customerFields?.map((item) => {
                        switch (item.type) {
                            case 1:
                            case 2:
                                item.mapping = Object.entries(item.mapping || {}).map((entry) => {
                                    return {
                                        pattern: entry[0],
                                        values: entry[1]
                                    }
                                });
                                return item;
                            default: return item;
                        }
                    }));
                }
            })();
        }
    }, [fieldItems, user]);

    return (
        <Row className="pt-3">
            <Col>
                <Card className="mx-auto w-auto">
                    <Card.Title>
                        <Navbar className="w-100">
                            <Nav className="me-auto">
                                <OverlayTrigger overlay={<Tooltip style={{ position: "fixed" }}>Reload fields configuration.<br /><em>Unsaved changes will be lost.</em></Tooltip>}
                                    placement="right"
                                    delay={{ show: 500, hide: 200 }}>
                                    <Button variant="outline-secondary"
                                        onClick={(event) => {
                                            setFieldItems(null);
                                            setCurrentItemWrapper({ item: {} });
                                        }}><Icons icon="download" /></Button>
                                </OverlayTrigger>
                            </Nav>
                            <Navbar.Text>Corectic Fields Configurator</Navbar.Text>
                            <Nav className="ms-auto">
                                <OverlayTrigger overlay={<Tooltip style={{ position: "fixed" }}>Upload fields configuration.<br /><em>All changes will take effect immediately.</em></Tooltip>}
                                    placement="left"
                                    delay={{ show: 500, hide: 200 }}>
                                    <Button variant="primary"
                                        disabled={(fieldItems == null) || storingConfiguration}
                                        onClick={(event) => {
                                            setStoringConfiguration(true);
                                            (async () => {
                                                try {
                                                    await axios.put(
                                                        `${API_URL}/customer/fields`,
                                                        {
                                                            fieldDefinitions: fieldItems.map((item) => {
                                                                switch (item.type) {
                                                                    case 1:
                                                                    case 2:
                                                                        return {
                                                                            ...item,
                                                                            mapping: item.mapping?.reduce((result, entry) => {
                                                                                result[entry.pattern] = entry.values;
                                                                                return result;
                                                                            }, {})
                                                                        };
                                                                    default:
                                                                        return item;
                                                                }
                                                            })
                                                        },
                                                        {
                                                            headers: {
                                                                'Content-Type': 'application/json',
                                                                'Access-Control-Allow-Credentials': 'true',
                                                                'Accept': '*/*',
                                                                'Authorization': `Bearer ${user?.token}`,
                                                                'X-Api-Key': user?.clientKey
                                                            },
                                                            validateStatus: () => true
                                                        }
                                                    );
                                                } finally {
                                                    setStoringConfiguration(false);
                                                }
                                            })();
                                        }}>{(storingConfiguration) ? <Spinner size="sm" /> : <Icons icon="upload" />}</Button>
                                </OverlayTrigger>
                            </Nav>
                        </Navbar>
                    </Card.Title>
                    <Card.Body className="bg-light" style={{ height: "calc(100vh / 4)", overflowY: "auto" }}>
                        <Table className="fs--1" hover={fieldItems != null} striped>
                            <thead className="bg-300">
                                <tr>
                                    <th>Field Code</th>
                                    <th>Weight</th>
                                    <th>API Parameter</th>
                                    <th>Batch Column</th>
                                </tr>
                            </thead>
                            <tbody>
                                {(fieldItems == null)
                                    ? <tr>
                                        <td colSpan={4}>Loading...</td>
                                    </tr>
                                    : <>
                                        {fieldItems.map((fieldItem, index) => {
                                            return (
                                                <tr key={`field-list-${fieldItem.code}-${index}`}
                                                    className={(fieldItem.code === currentItemWrapper?.item?.code) ? "table-primary" : ""}
                                                    onClick={(event) => {
                                                        event.preventDefault();
                                                        setCurrentItemWrapper({ item: fieldItem });
                                                    }}>
                                                    <td>{fieldItem.code}</td>
                                                    <td>{fieldItem.weight == null ? "n/a" : fieldItem.weight}</td>
                                                    <td>{fieldItem.apiParam}</td>
                                                    <td>{fieldItem.batchColumn}</td>
                                                </tr>
                                            );
                                        })}
                                    </>
                                }
                            </tbody>
                        </Table>
                    </Card.Body>
                    <Card.Body className="border-top border-3 border-secondary">
                        {(currentItemWrapper?.item?.code)
                            ? <Form>
                                <Container fluid={true}
                                    className="p-0">
                                    <Row>
                                        <Col xs={12} md={6} className="mb-2">
                                            <Row className="mb-2">
                                                <Col>
                                                    <FloatingLabel
                                                        controlId="corectic-field-code"
                                                        label="Field Code"
                                                        className="">
                                                        <Form.Control type="text" size="sm"
                                                            readOnly={true}
                                                            value={currentItemWrapper?.item?.code || ""}
                                                            placeholder="cust_fieldName" />
                                                        <Form.Text muted={true}>Code purpose description...</Form.Text>
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>
                                            <Row className="mb-2">
                                                <Col>
                                                    <FloatingLabel
                                                        controlId="corectic-field-apiParam"
                                                        label="API Parameter Name"
                                                        className="">
                                                        <Form.Control type="text" size="sm"
                                                            value={currentItemWrapper?.item?.apiParam || ""}
                                                            placeholder="apiParamName"
                                                            onChange={(event) => {
                                                                currentItemWrapper.item.apiParam = event.target.value;
                                                                setCurrentItemWrapper({ item: currentItemWrapper.item });
                                                            }} />
                                                        <Form.Text muted={true}>API parameter purpose description...</Form.Text>
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <FloatingLabel
                                                        controlId="corectic-field-batchColumn"
                                                        label="Batch Column Name"
                                                        className="">
                                                        <Form.Control type="text" size="sm"
                                                            value={currentItemWrapper?.item?.batchColumn || ""}
                                                            placeholder="Field Column Name"
                                                            onChange={(event) => {
                                                                currentItemWrapper.item.batchColumn = event.target.value;
                                                                setCurrentItemWrapper({ item: currentItemWrapper.item });
                                                            }} />
                                                        <Form.Text muted={true}>Batch column purpose description...</Form.Text>
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={12} md={6} className="mb-2" hidden={currentItemWrapper?.item?.code === "identity"}>
                                            <Row className="mb-2">
                                                <Col>
                                                    <FloatingLabel
                                                        controlId="corectic-field-type"
                                                        label="Type"
                                                        className="">
                                                        <Form.Select value={currentItemWrapper?.item?.type || ""} size="sm"
                                                            onChange={(event) => {
                                                                currentItemWrapper.item.type = parseInt(event.target.value);
                                                                setCurrentItemWrapper({ item: currentItemWrapper.item });
                                                            }}>
                                                            <option disabled value="">Choose field type...</option>
                                                            <option value="1">Strict Mapped Value</option>
                                                            <option value="2">Regex Mapped Value</option>
                                                            <option value="3">Right Closed Interval</option>
                                                            <option value="5">Overlapping Intervals</option>
                                                        </Form.Select>
                                                        <Form.Text muted={true} size="sm">Type purpose description...</Form.Text>
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>
                                            <Row className="mb-2">
                                                <Col>
                                                    <FloatingLabel
                                                        controlId="corectic-field-weight"
                                                        label="Weight"
                                                        className="">
                                                        <Form.Control type="number" size="sm"
                                                            value={currentItemWrapper?.item?.weight || "0"}
                                                            min={0}
                                                            placeholder="5"
                                                            onChange={(event) => {
                                                                currentItemWrapper.item.weight = parseInt(event.target.value);
                                                                setCurrentItemWrapper({ item: currentItemWrapper.item });
                                                            }} />
                                                        <Form.Text muted={true}>Weight purpose description...</Form.Text>
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={12} md={6} className="mb-2">
                                                    <FloatingLabel
                                                        controlId="corectic-field-transformation"
                                                        label="Type"
                                                        className="">
                                                        <Form.Select value={currentItemWrapper?.item?.transformation || ""} size="sm"
                                                            onChange={(event) => {
                                                                currentItemWrapper.item.transformation = event.target.value;
                                                                setCurrentItemWrapper({ item: currentItemWrapper.item });
                                                            }}>
                                                            <option value="">None</option>
                                                            <option value="1">from Date to Age</option>
                                                        </Form.Select>
                                                        <Form.Text muted={true} size="sm">Transformation purpose description...</Form.Text>
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} className="mb-2">
                                            <FieldConfiguration config={currentItemWrapper?.item} />
                                        </Col>
                                    </Row>
                                </Container>
                            </Form>
                            : <></>}
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
};

export default Configurator;