import React, {useEffect, useReducer, useState} from 'react';
import PropTypes from 'prop-types';
import AppContext, { AuthContext, ClientContext } from 'context/Context';
import { settings } from './config';
import {getColor, getItemFromStore, processLogin, STORAGE_KEY_TOKEN} from 'helpers/utils';
import { configReducer } from './reducers/configReducer';
import useToggleStyle from './hooks/useToggleStyle';

import { Chart as ChartJS, registerables } from 'chart.js';
import axios from "axios";
import {API_URL} from "./App";
import logoCorectic from 'assets/corectic/images/logo-corectic.svg';
import logoMyndrive from 'assets/corectic/images/logo-myndrive.png';

const defaultLogo = (["console.myndrive.com"].includes(window.location.hostname)) ? logoMyndrive : logoCorectic;

ChartJS.register(...registerables);

const Main = props => {
  const configState = {
    isFluid: getItemFromStore('isFluid', settings.isFluid),
    isRTL: getItemFromStore('isRTL', settings.isRTL),
    isDark: getItemFromStore('isDark', settings.isDark),
    navbarPosition: getItemFromStore('navbarPosition', settings.navbarPosition),
    disabledNavbarPosition: [],
    isNavbarVerticalCollapsed: getItemFromStore(
      'isNavbarVerticalCollapsed',
      settings.isNavbarVerticalCollapsed
    ),
    navbarStyle: getItemFromStore('navbarStyle', settings.navbarStyle),
    currency: settings.currency,
    showBurgerMenu: settings.showBurgerMenu,
    showSettingPanel: false,
    navbarCollapsed: false
  };

  const [config, configDispatch] = useReducer(configReducer, configState);
  const [user, setUser] = useState(null);
  const [clientConfig, setClientConfig] = useState(null);

  const { isLoaded } = useToggleStyle(
    config.isRTL,
    config.isDark,
    configDispatch
  );

  const setConfig = (key, value) => {
    configDispatch({
      type: 'SET_CONFIG',
      payload: {
        key,
        value,
        setInStore: [
          'isFluid',
          'isRTL',
          'isDark',
          'navbarPosition',
          'isNavbarVerticalCollapsed',
          'navbarStyle'
        ].includes(key)
      }
    });
  };

  useEffect(() => {
    const token = localStorage.getItem(STORAGE_KEY_TOKEN);
    processLogin(token, user, setUser);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (clientConfig == null && user?.token) {
      (async () => {
        const customerConfigResp = await axios.get(
            `${API_URL}/customer`,
            {
              headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': 'true',
                'Accept': '*/*',
                'Authorization': `Bearer ${user?.token}`,
                'X-Api-Key': user?.clientKey
              },
              validateStatus: () => true
            }
        );
        if ((200 <= customerConfigResp.status) && (customerConfigResp.status < 300)) {
          setClientConfig(customerConfigResp.data);

          const r = document.querySelector(':root');
          r.style.setProperty('--bri-header-bg-color', customerConfigResp.data?.headerBackground || "initial");
          r.style.setProperty('--bri-navigation-bg-color', customerConfigResp.data?.navigationBackground || "initial");          
        }
      })();

    }
  }, [clientConfig, user]);

  if (!isLoaded) {
    return (
      <div
        style={{
          position: 'fixed',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          backgroundColor: config.isDark ? getColor('dark') : getColor('light')
        }}
      />
    );
  }


  return (
    <AppContext.Provider value={{ config, setConfig, configDispatch }}>
      <AuthContext.Provider value={{ user, setUser }}>
        <ClientContext.Provider value={{ clientConfig, setClientConfig }}>
          {props.children}
        </ClientContext.Provider>
      </AuthContext.Provider>
    </AppContext.Provider>
  );
};

Main.propTypes = { children: PropTypes.node };

export default Main;
