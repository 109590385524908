import {
    Button,
    Card,
    Col,
    Container,
    FloatingLabel,
    Form,
    Modal,
    Nav,
    Row, Tab,
    Table
} from "react-bootstrap";
import {FontAwesomeIcon as Icons} from '@fortawesome/react-fontawesome';
import React, {useContext, useEffect, useRef, useState} from "react";
import axios from "axios";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import {API_URL} from "App";
import {BriActionBar, BriActionBarContainer, BriLockedTag} from "../components/BriActionBar";
import {AuthContext, ClientContext} from "../../context/Context";
import classNames from "classnames";
import {NavLink} from "react-router-dom";
import FalconCloseButton from "../../components/common/FalconCloseButton";
import SoftBadge from "../../components/common/SoftBadge";
import ProcessingModal from "../components/ProcessingModal";
import messages from "data/chat/messages";

const SingleCampaignBuilder = ({
                                   user,
                                   messageType,
                                   disabled,
                                   messageTypeData,
                                   setShowProcessingModal,
                                   ...otherProps
                               }) => {
    const sourceTextRef = useRef(null);
    const coreCTextRef = useRef(null);
    const coreOTextRef = useRef(null);
    const coreRTextRef = useRef(null);
    const coreETextRef = useRef(null);

    useEffect(() => {
        sourceTextRef.current.value = messageTypeData?.sourceText || "";
        [
            ["C", coreCTextRef],
            ["O", coreOTextRef],
            ["R", coreRTextRef],
            ["E", coreETextRef]
        ].forEach(([coreLetter, elemRef]) => {
            elemRef.current.readOnly = !messageTypeData?.manualText?.[coreLetter];
            elemRef.current.value = messageTypeData?.manualText?.[coreLetter] || messageTypeData?.aiText?.[coreLetter] || null;
            elemRef.current.originalAiValue = (messageTypeData?.manualText?.[coreLetter] ? messageTypeData?.aiText?.[coreLetter] : null) || null ;
        });

    }, [messageTypeData]);


    return (
        <>
            <Container fluid>
                <Row className="mb-2">
                    <Col xs={12}>
                        <FloatingLabel controlId={`${messageType}-formSourceText`}
                                       label="Your Marketing Text">
                            <Form.Control name={`${messageType}-formSourceText`}
                                          disabled={disabled}
                                          as="textarea"
                                          rows="7"
                                          style={{height: "auto"}}
                                          autoFocus={true}
                                          ref={sourceTextRef} 
                                          size="sm" />
                        </FloatingLabel>
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col xs={12}>
                        <Button variant="secondary" disabled={disabled} size="sm"
                                onClick={async (event) => {
                                    event?.preventDefault();

                                    try {
                                        setShowProcessingModal({
                                            show: true,
                                            title: "Generating Your Messages...",
                                            message: ["Please wait."]
                                        });
                                        const coreCampaignResp = await axios.post(`${API_URL}/build/campaign`,
                                            {
                                                text: sourceTextRef.current.value,
                                                phraseDecorator: messageType || "default",
                                            },
                                            {
                                                headers: {
                                                    'Content-Type': 'application/json',
                                                    'Access-Control-Allow-Credentials': 'true',
                                                    'Accept': '*/*',
                                                    'Authorization': `Bearer ${user?.token}`,
                                                    'X-Api-Key': user?.clientKey
                                                },
                                                validateStatus: () => true
                                            });
                                        if ((200 <= coreCampaignResp.status) && (coreCampaignResp.status < 300)) {
                                            [
                                                [coreCTextRef, "campaignTextC"],
                                                [coreOTextRef, "campaignTextO"],
                                                [coreRTextRef, "campaignTextR"],
                                                [coreETextRef, "campaignTextE"]
                                            ].forEach(([elemRef, respTextField]) => {
                                                if (elemRef.current.readOnly) {
                                                    elemRef.current.value = coreCampaignResp?.data?.[respTextField];
                                                    elemRef.current.originalAiValue = undefined;
                                                }
                                            });
                                        }
                                    } catch (err) {
                                        console.error(err);
                                    } finally {
                                        setShowProcessingModal({ show: false });
                                    }
                                }}
                                type="submit">Generate CORE</Button>
                    </Col>
                </Row>
                <Row className="mb-2">
                    {[
                        ["C", coreCTextRef],
                        ["O", coreOTextRef],
                        ["R", coreRTextRef],
                        ["E", coreETextRef],
                    ].map(([coreLetter, elemRef]) => (
                        <Col key={`core-${coreLetter?.toLowerCase()}-col`}
                             xs={12} md={6}
                             className={classNames("py-2", `bri-bg-core-${coreLetter.toLowerCase()}`)}>
                            <BriActionBarContainer as={FloatingLabel}
                                                   controlId={`${messageType}-core${coreLetter}Text`}
                                                   label={`Your Marketing Text Tailored for "${coreLetter}"`}>
                                <Form.Control name={`${messageType}-core${coreLetter}Text`}
                                              disabled={disabled}
                                              as="textarea"
                                              rows="7"
                                              style={{height: "auto"}}
                                              readOnly={true}
                                              ref={elemRef}
                                              size="sm" />
                                <BriLockedTag className="position-absolute top-0 end-0 pe-2" />
                                <BriActionBar className="bg-white rounded-1 position-absolute top-0 end-0">
                                    <BriActionBar.Item variant="outline-secondary"
                                                       className="py-0 mx-0"
                                                       size="sm"
                                                       icon="pencil-alt"
                                                       clickHandler={(event) => {
                                                           if (elemRef.current.readOnly) {
                                                               elemRef.current.originalAiValue = elemRef.current.value;
                                                               elemRef.current.readOnly = false;
                                                           } else {
                                                               elemRef.current.value = elemRef.current.originalAiValue;
                                                               elemRef.current.originalAiValue = undefined;
                                                               elemRef.current.readOnly = true;
                                                           }
                                                       }} />
                                </BriActionBar>
                            </BriActionBarContainer>
                        </Col>
                    ))}
                </Row>
                <Row className="mb-2">
                    <Col xs={12} md={6}>
                        <Form.Check type="switch"
                                    id={`${messageType}-finished`}
                                    name={`${messageType}-finished`}
                                    disabled={disabled}
                                    label="Mark completed"
                                    defaultChecked={messageTypeData?.finished} />
                    </Col>
                </Row>
            </Container>
        </>
    );
};

const MessageTypeTab = ({
                            eventKey,
                            messageTypeData,
                            disabled,
                            title,
                            ...otherProps}) => {
    return (
        <Nav.Item>
            <Nav.Link eventKey={eventKey} disabled={disabled}>
                {title}
                {messageTypeData && (messageTypeData.finished
                    ? (<sup><SoftBadge pill bg="success">Finished</SoftBadge></sup>)
                    : (<sup><SoftBadge pill bg="warning">In Progress</SoftBadge></sup>))}
            </Nav.Link>
        </Nav.Item>
    );
}

const MessageModal = ({
                          modalShow,
                          setModalShow,
                          messageData,
                          setMessageData,
                          deleteMessage,
                          lovMetadata,
                          user,
                          setShowProcessingModal,
                          ...otherProps
                       }) => {
    const [createMessageTypes, setCreateMessageTypes] = useState({});

    const handleClose = () => {
        console.log("handleClose");
        setModalShow(false);
    }

    return (
        <Modal className="fs--1" show={modalShow} backdrop="static" size="xl" onHide={handleClose}>
            <Modal.Header>
                <Modal.Title>{messageData?.id? messageData.title : "Message"}</Modal.Title>
                <FalconCloseButton onClick={handleClose} />
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={async (e) => {
                    e.preventDefault();

                    try {
                        setShowProcessingModal({
                            show: true,
                            title: "Storing Data...",
                            message: ["Please wait."]
                        });
                        console.log("Original message data:", messageData);
                        const newMessageData = {
                            id: messageData?.id,
                            brandId: document.getElementById("messageBrand").value,
                            campaignId: document.getElementById("messageCampaign").value,
                            title: document.getElementById("messageTitle").value,
                            messageTypes: messageData?.messageTypes || {
                                ...(createMessageTypes?.email ? {email: {}} : {}),
                                ...(createMessageTypes?.sms ? {sms: {}} : {}),
                                ...(createMessageTypes?.desc ? {desc: {}} : {}),
                            },
                        };
                        Object.entries(newMessageData.messageTypes).forEach(([messageType, messageTypeData]) => {
                            messageTypeData.sourceText = document.getElementById(`${messageType}-formSourceText`).value;
                            messageTypeData.aiText = {};
                            messageTypeData.manualText = {};
                            ["C", "O", "R", "E"].forEach((coreLetter) => {
                                const coreTextElem = document.getElementById(`${messageType}-core${coreLetter}Text`);
                                if (coreTextElem.readOnly) {
                                    messageTypeData.aiText[coreLetter] = coreTextElem.value;
                                } else {
                                    messageTypeData.manualText[coreLetter] = coreTextElem.value;
                                    messageTypeData.aiText[coreLetter] = coreTextElem.originalAiValue;
                                }
                            });
                            messageTypeData.finished = document.getElementById(`${messageType}-finished`).checked;
                        });
                        console.log("New message data:", newMessageData);

                        await setMessageData(newMessageData);
                    } catch (err) {
                        console.error(err);
                    } finally {
                        setShowProcessingModal({ show: false });
                    }
                }}>
                    <Row>
                        <Col xs={3}>
                            <FloatingLabel controlId="messageBrand"
                                           label="Brand">
                                <Form.Select size="sm" name="messageBrand"
                                             onChange={(e) => {
                                                 const brandId = e.target.value;
                                                 const campaignSelectElem = document.getElementById("messageCampaign");
                                                 for (let i = 0; i < campaignSelectElem.options.length; i++) {
                                                     const campaignOptionElem = campaignSelectElem.options[i];
                                                     // console.log("campaignOptionElem:", campaignOptionElem?.value, !lovMetadata?.allowed?.[brandId]?.[campaignOptionElem?.value]);
                                                     campaignOptionElem.disabled = !lovMetadata?.allowed?.[brandId]?.[campaignOptionElem?.value];
                                                     if (campaignOptionElem.disabled && (campaignOptionElem.value === campaignSelectElem.value)) {
                                                         campaignSelectElem.value = "";
                                                     }
                                                 }
                                                 setCreateMessageTypes({});
                                             }}
                                             disabled={!!messageData?.brandId}
                                             required
                                             defaultValue={messageData?.brandId || ""}>
                                    <option value=""
                                            disabled>(Select Brand)</option>
                                    {Object.entries(lovMetadata?.brands || {}).map(([brandId, brandName]) => (
                                        <option key={`brand-edit-${brandId}`}
                                                value={brandId}>{brandName}</option>
                                    ))}
                                </Form.Select>
                                {/*<Form.Text muted={true}>"Brand" purpose description...</Form.Text>*/}
                            </FloatingLabel>
                        </Col>
                        <Col xs={4}>
                            <FloatingLabel controlId="messageCampaign"
                                           label="Campaign">
                                <Form.Select size="sm" name="messageCampaign"
                                             onChange={(e) => {
                                                 // console.log("allowed:", lovMetadata?.allowed?.[document.getElementById("messageBrand").value]?.[e.target.value]);
                                                 setCreateMessageTypes(lovMetadata?.allowed?.[document.getElementById("messageBrand").value]?.[e.target.value] || {});
                                             }}
                                             disabled={!!messageData?.campaignId}
                                             required
                                             defaultValue={messageData?.campaignId || ""}>
                                    <option value=""
                                            disabled>(Select Campaign)</option>
                                    {Object.entries(lovMetadata?.campaigns || {})?.map(([campaignId, campaignName]) => (
                                        <option key={`campaign-edit-${campaignId}`}
                                                value={campaignId}>{campaignName}</option>
                                    ))}
                                </Form.Select>
                                {/*<Form.Text muted={true}>"Campaign" purpose description...</Form.Text>*/}
                            </FloatingLabel>
                        </Col>
                        <Col xs={5}>
                            <FloatingLabel controlId="messageTitle"
                                           label="Message Title">
                                <Form.Control size="sm" name="messageTitle"
                                              type="text"
                                              required
                                              disabled={!!messageData?.title}
                                              placeholder="Message Title"
                                              defaultValue={messageData?.title} />
                                {/*<Form.Text muted={true}>"Message" purpose description...</Form.Text>*/}
                            </FloatingLabel>
                        </Col>
                    </Row>
                    <Tab.Container id="messageTabs"
                                   defaultActiveKey={messageData?.messageTypes?.email ? "email" : (messageData?.messageTypes?.sms ? "sms" : "desc")}
                                   transition={false}>
                        <Row className="pt-3">
                            <Col xs={12}>
                                <Nav className="border-0 border-bottom nav-pills pb-3">
                                    <MessageTypeTab eventKey="email"
                                                    title="Email"
                                                    disabled={!(messageData?.messageTypes?.email || createMessageTypes?.email)}
                                                    messageTypeData={messageData?.messageTypes?.email} />
                                    <MessageTypeTab eventKey="sms"
                                                    title="SMS"
                                                    disabled={!(messageData?.messageTypes?.sms || createMessageTypes.sms)}
                                                    messageTypeData={messageData?.messageTypes?.sms} />
                                    <MessageTypeTab eventKey="desc"
                                                    title="Description"
                                                    disabled={!(messageData?.messageTypes?.desc || createMessageTypes.desc)}
                                                    messageTypeData={messageData?.messageTypes?.desc} />
                                </Nav>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <Tab.Content className="pt-3 border-x border-bottom">
                                    <Tab.Pane eventKey="email">
                                        <SingleCampaignBuilder user={user}
                                                               messageType="email"
                                                               disabled={!(messageData?.messageTypes?.email || createMessageTypes.email)}
                                                               messageTypeData={messageData?.messageTypes?.email}
                                                               setShowProcessingModal={setShowProcessingModal} />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="sms">
                                        <SingleCampaignBuilder user={user}
                                                               messageType="sms"
                                                               disabled={!(messageData?.messageTypes?.sms || createMessageTypes.sms)}
                                                               messageTypeData={messageData?.messageTypes?.sms}
                                                               setShowProcessingModal={setShowProcessingModal} />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="desc">
                                        <SingleCampaignBuilder user={user}
                                                               messageType="desc"
                                                               disabled={!(messageData?.messageTypes?.desc || createMessageTypes.desc)}
                                                               messageTypeData={messageData?.messageTypes?.desc}
                                                               setShowProcessingModal={setShowProcessingModal} />
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                        <Row className="pt-3">
                            <Col xs={6} className="d-flex justify-content-start">
                                <Button variant="primary" type="submit" size="sm">Save&nbsp;All&nbsp;Changes</Button>
                            </Col>
                            <Col xs={6} className="d-flex justify-content-end">
                                {messageData.id && <Button variant="link" size="sm" onClick={() => deleteMessage(messageData?.id)}>Delete</Button>}
                            </Col>
                        </Row>
                    </Tab.Container>
                </Form>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-start">
                <Button variant="link"
                        onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

const Actions = ({
                     rowData,
                     editActionHandler,
                     ...otherProps
                 }) => {
    return (
        <div className="end-0 pe-3 hover-actions"
             style={{marginTop: "-5px"}}>
            <Button variant="light"
                    size="sm"
                    className="border-300 me-1 text-600"
                    onClick={(e) => {
                        e.preventDefault();
                        editActionHandler(rowData, e);
                    }}>
                <Icons icon="edit" />
            </Button>
        </div>
    );
};

const StatusCheck = ({
                         messageType,
                         ...otherProps
}) => {
    if (messageType) {
        return (
            <Form.Check type="checkbox"
                        checked={messageType?.finished}
                        disabled/>
        );
    } else {
        return (<>&nbsp;</>);
    }
}

const TableRow = ({
                      rowData,
                      lovMetadata,
                      editActionHandler,
                      checkboxChangeHandler,
                      ...otherProps
                  }) => {

    return (
        <tr className="hover-actions-trigger">
            <td><Form.Check type="checkbox" onClick={checkboxChangeHandler} checked={rowData?.checked} /></td>
            <td>{lovMetadata?.brands?.[rowData?.brandId]}</td>
            <td>{lovMetadata?.campaigns?.[rowData?.campaignId]}</td>
            <td>{rowData.title}</td>
            <td><StatusCheck messageType={rowData.messageTypes?.email}/></td>
            <td><StatusCheck messageType={rowData.messageTypes?.sms}/></td>
            <td><StatusCheck messageType={rowData.messageTypes?.desc}/></td>
            <td className="w-auto">
                <Actions rowData={rowData}
                         editActionHandler={editActionHandler}/>
            </td>
        </tr>
    );
};

const CampaignBuilder = ({
                             ...otherProps
}) => {
    const { user} = useContext(AuthContext);
    const { clientConfig } = useContext(ClientContext);

    const [selectedRowIds, setSelectedRowIds] = useState(null);
    const [lovMetadata, setLovMetadata] = useState(null);
    const [messageItems, setMessageItems] = useState(null);
    const [formattedMessageItems, setFormattedMessageItems] = useState(null);
    const [messageModalVisible, setMessageModalVisible] = useState(false);
    const [messageModalData, setMessageModalData] = useState({});
    const [filter, setFilter] = useState({
        brandId: '',
        campaignId: '',
        status: ''
    });
    const [showProcessingModal, setShowProcessingModal] = useState({
        show: false,
        title: "Processing Your Request...",
        message: ["Please wait."]
    });
    const showMessageModal = (messageData) => {
        setMessageModalData(messageData);
        setMessageModalVisible(true);
    }
    
    const exportToCSV = (tableData, fileName) => {        
        let items = tableData.filter(
            (message) => 
                message.checked === true &&
                (filter?.brandId && message.brandId === filter?.brandId || filter?.brandId === '') &&
                (filter?.campaignId && message.campaignId === filter?.campaignId || filter?.campaignId === '') && 
                (
                    filter?.status === "" ||
                    (filter?.status === "completed" && [message.messageTypes?.email?.finished,message.messageTypes?.sms?.finished, message.messageTypes?.desc?.finished].every(value => value !== false)) ||
                    (filter?.status === "incomplete" && [message.messageTypes?.email?.finished,message.messageTypes?.sms?.finished, message.messageTypes?.desc?.finished].every(value => value !== false) === false)
                )
        );

        let formattedItems = Object.keys(items).map(key => ({                
                'brandId': lovMetadata?.brands?.[items[key]?.brandId],
                'campaignId': lovMetadata?.campaigns?.[items[key]?.campaignId],
                'title': items[key]?.title,
                'email': items[key]?.messageTypes?.email?.finished,
                'sms': items[key]?.messageTypes?.sms?.finished,
                'desc': items[key]?.messageTypes?.desc?.finished,
             }));

        const fileType ="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const fileExtension = ".xlsx";
        const ws = XLSX.utils.json_to_sheet(formattedItems);
        /* HEADERS */
        XLSX.utils.sheet_add_aoa(ws, [["Brand", "Campaign", "Message", "Email", "SMS", "Desc."]], { origin: "A1" });
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    }

    useEffect(() => {
        if (lovMetadata == null && clientConfig !== null) {
            setLovMetadata({
                brands: clientConfig?.brands?.reduce((result, item) => {
                    result[item.id] = item.name;
                    return result;
                }, {}),
                campaigns: clientConfig?.campaigns?.reduce((result, item) => {
                    result[item.id] = item.name;
                    return result;
                }, {}),
                allowed: clientConfig?.allowedCampaigns?.reduce((result, item) => {
                    if (!result[item.brandId]) {
                        result[item.brandId] = {};
                    }
                    if (!result[item.brandId][item.campaignId]) {
                        result[item.brandId][item.campaignId] = {};
                    }
                    result[item.brandId][item.campaignId] = item.messageTypes;
                    return result;
                }, {}),
            });
        }
    }, [lovMetadata, clientConfig]);

    useEffect(() => {
        if (messageItems == null) {
            (async () => {
                const customerConfigResp = await axios.get(
                    `${API_URL}/build/campaign`,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Access-Control-Allow-Credentials': 'true',
                            'Accept': '*/*',
                            'Authorization': `Bearer ${user?.token}`,
                            'X-Api-Key': user?.clientKey,
                        },
                        validateStatus: () => true,
                    }
                );
                if ((200 <= customerConfigResp.status) && (customerConfigResp.status < 300)) {
                    setMessageItems(customerConfigResp?.data?.items);
                }
            })();
        }
    }, [messageItems, user]);

    const handleFilterMessageItems = (type, selected) => {                
        setFilter({...filter, [type]: selected});        
    }

    const handleCheckboxChange = (id) => {
        const updatedMessageItems = messageItems.map((message) =>
            message.id === id ? { ...message, checked: !message.checked} : message
        );
        setMessageItems(updatedMessageItems);
    };

    const handleCheckAll = () => {
        const allChecked = messageItems.every((message) => message?.checked);
        const updatedMessageItems = messageItems.map((message) => ({
            ...message, checked: !allChecked,
            })
        );
        setMessageItems(updatedMessageItems);
    }
    
    return (
        <div>
            <Row className="fs--1">
                <Col xs={12}>
                    <Card>
                        <Card.Header>
                            <Row className="align-items-center">
                                <Col xs={12}>
                                    <h5 className="mb-0">Message Maker</h5>
                                </Col>
                            </Row>
                        </Card.Header>
                    </Card>
                </Col>
            </Row>
            <Row className="pt-4 fs--1">
                <Col xs={12}>
                    <Card>
                        <Card.Body>
                            <Button variant="primary" className="position-absolute"
                                    onClick={(event) => {
                                        showMessageModal({});
                                    }}
                                    style={{
                                        right: "1.25rem",
                                        top: "-4.4rem"
                                    }}
                                    size="sm">Create New</Button>
                            <MessageModal modalShow={messageModalVisible}
                                          setModalShow={setMessageModalVisible}
                                          messageData={messageModalData}
                                          setMessageData={async (newMessageData) => {
                                              const updateCampaignResp = await axios.put(`${API_URL}/build/campaign`,
                                                  newMessageData,
                                                  {
                                                      headers: {
                                                          'Content-Type': 'application/json',
                                                          'Access-Control-Allow-Credentials': 'true',
                                                          'Accept': '*/*',
                                                          'Authorization': `Bearer ${user?.token}`,
                                                          'X-Api-Key': user?.clientKey
                                                      },
                                                      validateStatus: () => true
                                                  });
                                              if ((200 <= updateCampaignResp.status) && (updateCampaignResp.status < 300)) {
                                                  setMessageItems(null);
                                                  setMessageModalVisible(false);
                                              }
                                          }}
                                          deleteMessage={async (messageId) => {                                            
                                            try {
                                                const updateCampaignResp = await axios.delete(`${API_URL}/build/campaign/${messageId}`,
                                                    {
                                                        headers: {
                                                            'Content-Type': 'application/json',
                                                            'Access-Control-Allow-Credentials': 'true',
                                                            'Accept': '*/*',
                                                            'Authorization': `Bearer ${user?.token}`,
                                                            'X-Api-Key': user?.clientKey
                                                        },
                                                        validateStatus: () => true
                                                    });
                                                if ((200 <= updateCampaignResp.status) && (updateCampaignResp.status < 300)) {
                                                    setMessageItems(null);
                                                    setMessageModalVisible(false);
                                                }
                                            
                                            } catch (err) {
                                                console.error(err);
                                            } finally {
                                                setShowProcessingModal({ show: false });
                                            }
                                          }}
                                          user={user}
                                          lovMetadata={lovMetadata}
                                          setShowProcessingModal={setShowProcessingModal} />
                            <ProcessingModal show={showProcessingModal?.show}
                                             title={showProcessingModal?.title}
                                             message={showProcessingModal?.message} />
                            <Table hover responsive striped className="mt-1">
                                <thead>
                                    <tr>
                                        <th scope="col" colSpan={2} className="px-1">
                                            <Form.Select size="sm" defaultValue="" onChange={(e) => handleFilterMessageItems("brandId", e.target.value)}>
                                                <option value="">(All)</option>
                                                {Object.entries(lovMetadata?.brands || {}).map(([brandId, brandName]) => (
                                                    <option key={`brand-filter-${brandId}`}
                                                            value={brandId}>{brandName}</option>
                                                ))}
                                            </Form.Select>
                                        </th>
                                        <th scope="col" className="px-1">
                                            <Form.Select name="campaignId" size="sm" defaultValue="" onChange={(e) => handleFilterMessageItems("campaignId", e.target.value)}>
                                                <option value="">(All)</option>
                                                {Object.entries(lovMetadata?.campaigns || {}).map(([campaignId, campaignName]) => (
                                                    <option key={`campaign-filter-${campaignId}`}
                                                            value={campaignId}>{campaignName}</option>
                                                ))}
                                            </Form.Select>
                                        </th>                                        
                                        <th scope="col" className="px-1">
                                            <Form.Select size="sm" defaultValue="" onChange={(e) => handleFilterMessageItems("status", e.target.value)}>
                                                <option value="">(All)</option>
                                                <option value="incomplete">Unfinished</option>
                                                <option value="completed">Finished</option>
                                            </Form.Select>
                                        </th>
                                        <th scope="col" className="px-1"></th>                                        
                                        <th scope="col" colSpan={3} className="px-1"><Button variant="primary" size="sm" onClick={() => exportToCSV(messageItems,"Messages")}>Export to Excel</Button></th>
                                    </tr>
                                    <tr className="bg-300">
                                        <th scope="col"><Form.Check type='checkbox' checked={messageItems && messageItems.every((message) => message?.checked)} id='defaultCheckbox' label='' onChange={handleCheckAll} /></th>
                                        <th scope="col" width="15%">Brand</th>
                                        <th scope="col" width="25%">Campaign</th>
                                        <th scope="col" width="35%">Message</th>
                                        <th scope="col" width="5%">Email</th>
                                        <th scope="col" width="5%">SMS</th>
                                        <th scope="col" width="5%">Desc.</th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>                                
                                <tbody>
                                    {messageItems
                                        ? messageItems.map((message) => (
                                            (filter?.brandId && message.brandId === filter?.brandId || filter?.brandId === '') &&
                                            (filter?.campaignId && message.campaignId === filter?.campaignId || filter?.campaignId === '') && (
                                                filter?.status === "" ||
                                                (filter?.status === "completed" && [message.messageTypes?.email?.finished,message.messageTypes?.sms?.finished, message.messageTypes?.desc?.finished].every(value => value !== false)) ||
                                                (filter?.status === "incomplete" && [message.messageTypes?.email?.finished,message.messageTypes?.sms?.finished, message.messageTypes?.desc?.finished].every(value => value !== false) === false)
                                            ) &&
                                            <TableRow key={message?.id}
                                                      rowData={message}
                                                      lovMetadata={lovMetadata}
                                                      editActionHandler={() => {
                                                          showMessageModal(message);
                                                      }}
                                                      checkboxChangeHandler={() => {handleCheckboxChange(message.id)}}
                                                      />))
                                        : (
                                            <tr>
                                                <td colSpan="*">Loading...</td>
                                            </tr>)}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default CampaignBuilder;