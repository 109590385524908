import {
    Badge,
    Button, ButtonGroup,
    Card,
    Col,
    Container,
    FloatingLabel,
    Form,
    Modal,
    Nav,
    Row, Tab,
    Table, ToggleButton, ToggleButtonGroup
} from "react-bootstrap";
import {FontAwesomeIcon as Icons} from '@fortawesome/react-fontawesome';
import React, {useContext, useEffect, useRef, useState} from "react";
import axios from "axios";
import {API_URL} from "App";
import {BriActionBar, BriActionBarContainer, BriLockedTag} from "../components/BriActionBar";
import {AuthContext, ClientContext} from "../../context/Context";
import classNames from "classnames";
import {NavLink} from "react-router-dom";
import FalconCloseButton from "../../components/common/FalconCloseButton";
import SoftBadge from "../../components/common/SoftBadge";
import ProcessingModal from "../components/ProcessingModal";

const UserModal = ({
                       modalShow,
                       userData,
                       lovMetadataBrands,
                       handleClose,
                       setShowProcessingModal,
                       ...otherProps
}
) => {
    const { user } = useContext(AuthContext);

    return (
        <Modal show={modalShow}
               backdrop="static"
               size="lg"
               className="fs--1"
               onHide={handleClose}>
            <Modal.Header>
                <Modal.Title>{userData?.sub? "User" : "Create New User"}</Modal.Title>
                <FalconCloseButton onClick={handleClose}/>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={async (e) => {
                    e.preventDefault();

                    try {
                        setShowProcessingModal?.({
                            show: true,
                            title: "Storing Data...",
                            message: ["Please wait."]
                        });

                        const role = document.getElementById("client_administrator").checked ? "client_administrator" :
                        document.getElementById("client_brand_administrator").checked ? "client_brand_administrator" :
                        "client_user";

                        const newUserData = {
                            firstName: document.getElementById("firstName").value,
                            lastName: document.getElementById("lastName").value,
                            email: document.getElementById("email").value,
                            role: role,
                            allowedBrands: Array.from(document.getElementsByName("allowedBrands"))
                            .filter((item) => item.checked)
                            .map((item) => item.id)
                        }

                        const newUsersResp = await axios.post(
                            `${API_URL}/user`,
                            newUserData,
                            {
                                headers: {
                                    'Content-Type': 'application/json',
                                    'Access-Control-Allow-Credentials': 'true',
                                    'Accept': '*/*',
                                    'Authorization': `Bearer ${user?.token}`,
                                    'X-Api-Key': user?.clientKey
                                },
                                validateStatus: () => true
                            }
                        )

                        handleClose(true);
                    } catch (err) {
                        console.error(err);
                    } finally {
                        setShowProcessingModal?.({ show: false });
                    }
                }}>
                    <Row>
                        <Col xs={6}>
                            <FloatingLabel controlId="firstName"
                                           label="First Name">
                                <Form.Control size="sm"
                                              name="firstName"
                                              type="text"
                                              required
                                              placeholder="First Name"
                                              defaultValue={userData?.firstName} />
                                {/*<Form.Text muted={true}>"Message" purpose description...</Form.Text>*/}
                            </FloatingLabel>
                        </Col>
                        <Col xs={6}>
                            <FloatingLabel controlId="lastName"
                                           label="Last Name">
                                <Form.Control size="sm"
                                              name="lastName"
                                              type="text"
                                              required
                                              placeholder="Last Name"
                                              defaultValue={userData?.lastName} />
                                {/*<Form.Text muted={true}>"Message" purpose description...</Form.Text>*/}
                            </FloatingLabel>
                        </Col>
                    </Row>
                    <Row className="pt-3">
                        <Col xs={12}>
                            <FloatingLabel controlId="email"
                                           label="Email">
                                <Form.Control size="sm"
                                              name="email"
                                              type="email"
                                              required
                                              placeholder="Email"
                                              disabled={!!userData?.email}
                                              defaultValue={userData?.email} />
                                {/*<Form.Text muted={true}>"Message" purpose description...</Form.Text>*/}
                            </FloatingLabel>
                        </Col>
                    </Row>
                    <Row className="pt-3">
                        <Col xs={4}>
                            <Form.Check type="radio"
                                        id="client_administrator"
                                        name="role"
                                        label="System Administrator"
                                        defaultChecked={userData?.role === "client_administrator"} />
                        </Col>
                        <Col xs={4}>
                            <Form.Check type="radio"
                                        id="client_brand_administrator"
                                        name="role"
                                        label="Brand Manager"
                                        defaultChecked={userData?.role === "client_brand_administrator"} />
                        </Col>
                        <Col xs={4}>
                            <Form.Check type="radio"
                                        id="client_user"
                                        name="role"
                                        label="User"
                                        defaultChecked={userData?.role === "client_user"} />
                        </Col>
                    </Row>
                    <Row className="pt-3">
                        <Col xs={12}>Brands:</Col>
                    </Row>
                    <Row className="pt-3">
                        {Object.entries(lovMetadataBrands || {}).map(([brandId, brandName]) => (
                            <Col xs={4} key={brandId}>
                                <Form.Check type="checkbox"
                                            id={brandId}
                                            name="allowedBrands"
                                            defaultChecked={userData?.allowedBrands?.includes(brandId)}
                                            label={brandName} />
                            </Col>
                        ))}
                    </Row>
                    <Row className="mt-4">
                        <Col xs={6} className="d-flex justify-content-start">
                            <Button variant="primary" type="submit" size="sm">Save&nbsp;Changes</Button>
                        </Col>
                        <Col xs={6} className="d-flex justify-content-end">
                            {userData?.sub && <Button variant="link" size="sm">Delete</Button>}
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-start">
                <Button variant="link"
                        onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

const Actions = ({
                     rowData,
                     editActionHandler,
                     ...otherProps
                 }) => {
    return (
        <div className="end-0 pe-3 hover-actions"
             style={{marginTop: "-5px"}}>
            <Button variant="light"
                    size="sm"
                    className="border-300 me-1 text-600"
                    onClick={(e) => {
                        e.preventDefault();
                        editActionHandler(rowData, e);
                    }}>
                <Icons icon="edit" />
            </Button>
        </div>
    );
};

const TableRow = ({
                      userData,
                      lovMetadataBrands,
                      editActionHandler,
                      ...otherProps
                  }) => {

    return (
        <tr className="hover-actions-trigger">
            <td>{userData?.allowedBrands?.map((brandId) => (<SoftBadge className="me-1" bg='dark'>{lovMetadataBrands?.[brandId]}</SoftBadge> ))}</td>
            <td>{userData?.firstName}&nbsp;{userData?.lastName}</td>
            <td><Form.Check type="radio"
                            checked={userData?.role === "client_administrator"}
                            disabled /></td>
            <td><Form.Check type="radio"
                            checked={userData?.role === "client_brand_administrator"}
                            disabled /></td>
            <td><Form.Check type="radio"
                            checked={userData?.role === "client_user"}
                            disabled /></td>
            <td className="w-auto">
                <Actions rowData={userData}
                         editActionHandler={editActionHandler}/>
            </td>
        </tr>
    );
};

const UserManager = ({
                             ...otherProps
}) => {
    const { user} = useContext(AuthContext);
    const { clientConfig } = useContext(ClientContext);

    const [lovMetadataBrands, setLovMetadataBrands] = useState(null);
    const [userItems, setUserItems] = useState(null);
    const [userModal, setUserModal] = useState({
        show: false,
        user: null,
    });
    const [showProcessingModal, setShowProcessingModal] = useState({
        show: false,
        title: "Processing Your Request...",
        message: ["Please wait."]
    });

    useEffect(() => {
        if (userItems == null) {
            (async () => {
                const userListResp = await axios.get(
                    `${API_URL}/user`,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Access-Control-Allow-Credentials': 'true',
                            'Accept': '*/*',
                            'Authorization': `Bearer ${user?.token}`,
                            'X-Api-Key': user?.clientKey,
                        },
                        validateStatus: () => true,
                    }
                );
                if ((200 <= userListResp.status) && (userListResp.status < 300)) {
                    setUserItems(userListResp?.data?.items);
                }
            })();
        }
    }, [userItems, user]);

    useEffect(() => {
        if (lovMetadataBrands == null) {
            setLovMetadataBrands(clientConfig?.brands?.reduce((result, item) => {
                    result[item.id] = item.name;
                    return result;
                }, {}));
        }
    }, [lovMetadataBrands, clientConfig, user]);

    return (
        <Row className="pt-3 fs--1">
            <Col xs={12}>
                <Card>
                    <Card.Body>
                        <Button variant="primary" className="position-absolute"
                                onClick={(event) => {
                                    setUserModal({
                                        show: true,
                                        user: {},
                                    });
                                }}
                                style={{
                                    right: "1.25rem",
                                    top: "-5.5rem"
                                }}
                                size="sm">Create New</Button>
                        <ProcessingModal show={showProcessingModal?.show}
                                         title={showProcessingModal?.title}
                                         message={showProcessingModal?.message} />
                        <UserModal modalShow={userModal?.show}
                                   userData={userModal?.user}
                                   lovMetadataBrands={lovMetadataBrands}
                                   handleClose={(refresh) => {
                                       setUserModal({show: false, user: null});
                                       if (refresh) {
                                           setUserItems(null);
                                       }
                                   }} />
                        <Table hover responsive striped className="mt-1">
                            <thead>
                                <tr className="bg-300">
                                    <th scope="col">Brand</th>
                                    <th scope="col">User</th>
                                    <th scope="col" width="5%">Admin</th>
                                    <th scope="col" width="10%">Brand Admin</th>
                                    <th scope="col" width="5%">User</th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {(userItems)
                                    ? userItems.map((userItem) => (
                                        <TableRow key={userItem.sub}
                                                  userData={userItem}
                                                  lovMetadataBrands={lovMetadataBrands}
                                                  editActionHandler={() => {
                                                      setUserModal({
                                                          show: true,
                                                          user: userItem,
                                                      });
                                                  }} />))
                                    : ( <tr>
                                            <td colSpan="6">Loading...</td>
                                        </tr>)}
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
};

export default UserManager;