import {NavLink, Outlet} from "react-router-dom";
import React, {useContext} from "react";
import {Card, Col, Nav, Row} from "react-bootstrap";
import {AuthContext} from "../../context/Context";
import classNames from "classnames";


const SettingsLayout = ({
                            ...otherProps
}) => {
    const { user } = useContext(AuthContext);

    return (
        <div>
            <Row className="fs--1">
                <Col xs={12}>
                    <Card>
                        <Card.Header>
                            <Row className="align-items-center">
                                <Col xs={12}>
                                    <h5 className="mb-0">Settings</h5>
                                </Col>
                            </Row>
                            <Row className="align-items-center py-3">
                                <Col xs={12}>
                                    <Nav className={classNames("bri-menu-nav", "list-group-horizontal", "nav-pills", otherProps?.className)}>
                                        <Nav.Item>
                                            <Nav.Link
                                                      disabled={false}
                                                      as={NavLink}
                                                      to="/settings/campaigns">Campaigns</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link
                                                      disabled={false}
                                                      as={NavLink}
                                                      to="/settings/users">Users</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>  
                                            <Nav.Link
                                                      disabled={false}
                                                      as={NavLink}
                                                      to="/settings/mappings">Mappings</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link
                                                      disabled={false}
                                                      as={NavLink}
                                                      to="/settings/client-setup">Client&nbsp;Setup</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </Col>
                            </Row>
                        </Card.Header>
                    </Card>
                </Col>
            </Row>
            {/*------ Settings Routes ------*/}
            <Outlet/>
        </div>
    );
};

export default SettingsLayout;
