/**
 * react-bootstrap lambda style component to be shown during long running task execution
 */
import React from "react";
import {Modal, Spinner} from "react-bootstrap";

const ProcessingModal = ({
                                    show,
                                    size = "lg",
                                    message = ["Please wait."],
                                    title = "Processing..." }) => {
    return (
        <Modal show={show}
               backdrop="static"
               size={size}
               keyboard={false}
               centered>
            <Modal.Header  className="justify-content-center">
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
                <Spinner animation="border" variant="secondary"/><br/>
                {Array.isArray(message)
                    ? message.map((text, index) => (<React.Fragment key={index}>{text}<br/></React.Fragment>))
                    : message}
            </Modal.Body>
        </Modal>
    );
}

export default ProcessingModal;