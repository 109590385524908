import React from "react";

import "./BriHorizontalStack.css";

export const BriHorizontalStack = ({
                                       children,
                                       ...otherProps
}) => {
    return (
        <div {...otherProps}
            className={["bri-horizontal-stack", otherProps?.className].join(' ')}>
            {children}
        </div>
    );
};

const BriHorizontalStackItem = ({
                                    children,
                                    ...otherProps
}) => {
    return (
        <div {...otherProps}
             className={["bri-horizontal-stack-item", otherProps?.className].join(' ')}>
            {children}
        </div>
    );
};
BriHorizontalStack.Item = BriHorizontalStackItem;