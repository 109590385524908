import {Button, Card, Col, Container, FormControl, InputGroup, Modal, Row} from "react-bootstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import React, {useContext, useEffect, useRef, useState} from "react";
import copyToClipboard from 'copy-to-clipboard';
import axios from "axios";
import { FontAwesomeIcon as Icons } from '@fortawesome/react-fontawesome';
import {API_URL} from "App";
import {AuthContext} from "../../context/Context";

const BatchOverview = ({
                           ...otherProps
}) => {
    const { user } = useContext(AuthContext);

    const selectedFileRef = useRef();
    const [selectedFile, setSelectedFile] = useState(undefined);
    const [uploading, setUploading] = useState(false);
    const [batchJobs, setBatchJobs] = useState(null);
    const [downloadLink, setDownloadLink] = useState(null);

    const handleCloseLinkModal = () => {
        if (downloadLink) {
            setDownloadLink(null);
        }
    };

    useEffect(() => {
        if (!batchJobs) {
            (async () => {
                const batchResp = await axios.get(
                    `${API_URL}/batch`,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Access-Control-Allow-Credentials': 'true',
                            'Accept': '*/*',
                            'Authorization': `Bearer ${user?.token}`,
                            'X-Api-Key': user?.clientKey
                        },
                        validateStatus: () => true
                    }
                );
                if ((200 <= batchResp.status) && (batchResp.status < 300)) {
                    setBatchJobs(batchResp?.data?.items);
                }
            })();
        }
    }, [user, batchJobs]);

    return (
        <Row className="pt-3 fs--1">
            <Col>
                <Card>
                    <Card.Header>
                        <InputGroup>
                            <FormControl type="file"
                                         ref={selectedFileRef}
                                         size="sm"
                                         onChange={(event) => {
                                             setSelectedFile(event.target.files[0]);
                                         }} />
                            <Button disabled={!selectedFile || uploading} size="sm"
                                    onClick={async (event) => {
                                        try {
                                            setUploading(true);
                                            const uploadUrlResp = await axios.get(
                                                `${API_URL}/batch/upload/url`,
                                                {
                                                    headers: {
                                                        'Content-Type': 'application/json',
                                                        'Access-Control-Allow-Credentials': 'true',
                                                        'Accept': '*/*',
                                                        'Authorization': `Bearer ${user?.token}`,
                                                        'X-Api-Key': user?.clientKey
                                                    }
                                                }
                                            );
                                            await axios.put(
                                                uploadUrlResp?.data?.url,
                                                selectedFile,
                                                {
                                                    headers: {
                                                        'Content-Type': 'text/csv',
                                                        'x-ms-blob-type': 'BlockBlob'
                                                    }
                                                }
                                            );

                                            selectedFileRef.current.value = "";
                                            setSelectedFile(undefined);
                                            setBatchJobs(null);
                                        } finally {
                                            setUploading(false);
                                        }
                                    }}>
                                {uploading ? 'Uploading...' : 'Upload'}
                            </Button>
                        </InputGroup>
                    </Card.Header>
                    <Card.Body className="bg-light">
                        {(batchJobs)
                            ? <BootstrapTable classes="fs--1" keyField="id"
                                              data={batchJobs}
                                              columns={[
                                                  {
                                                      dataField: "id",
                                                      text: "Id",
                                                      sort: false,
                                                      classes: "text-truncate",
                                                      title: true,
                                                  },
                                                  {
                                                      dataField: "createdAt",
                                                      text: "Created",
                                                      type: "date",
                                                      sort: true,
                                                      classes: "text-truncate",
                                                      title: true,
                                                  },
                                                  {
                                                      dataField: "finishedAt",
                                                      text: "Finished",
                                                      type: "date",
                                                      sort: true,
                                                      classes: "text-truncate",
                                                      title: true,
                                                  },
                                                  {
                                                      dataField: "statistics.totalCount",
                                                      text: "Record Count",
                                                      type: "number",
                                                      sort: true,
                                                      classes: "text-truncate",
                                                  },
                                                  {
                                                      dataField: "statistics.cCount",
                                                      text: "C Count",
                                                      type: "number",
                                                      sort: true,
                                                      classes: "text-truncate",
                                                  },
                                                  {
                                                      dataField: "statistics.oCount",
                                                      text: "O Count",
                                                      type: "number",
                                                      sort: true,
                                                      classes: "text-truncate",
                                                  },
                                                  {
                                                      dataField: "statistics.rCount",
                                                      type: "number",
                                                      text: "R Count",
                                                      sort: true,
                                                      classes: "text-truncate",
                                                  },
                                                  {
                                                      dataField: "statistics.eCount",
                                                      type: "number",
                                                      text: "E Count",
                                                      sort: true,
                                                      classes: "text-truncate",
                                                  },
                                              ]}
                                              defaultSorted={[{dataField: "createdAt", order: "desc"}]}
                                              expandRow={{
                                                  renderer: (rowData) => {
                                                      return (
                                                          <Card className="">
                                                              <Card.Body>
                                                                  <Row>
                                                                      <Col xs={12} md={6}>
                                                                          <div style={{ fontSize: "10px" }}>Batch ID</div>
                                                                          <div>{rowData.id}</div>
                                                                      </Col>
                                                                      <Col xs={12} md={6}>
                                                                          {(rowData.finishedAt)
                                                                              ? <Col xs={12} md={6}>
                                                                                  <Button variant="outline-secondary" size="sm"
                                                                                          onClick={(event) => {
                                                                                              setDownloadLink("");
                                                                                              (async () => {
                                                                                                  const downloadUrlResp = await axios.get(
                                                                                                      `${API_URL}/batch/download/url?batchId=${rowData.id}`,
                                                                                                      {
                                                                                                          headers: {
                                                                                                              'Content-Type': 'application/json',
                                                                                                              'Access-Control-Allow-Credentials': 'true',
                                                                                                              'Accept': '*/*',
                                                                                                              'Authorization': `Bearer ${user?.token}`,
                                                                                                              'X-Api-Key': user?.clientKey
                                                                                                          }
                                                                                                      }
                                                                                                  );
                                                                                                  if ((200 <= downloadUrlResp.status) && (downloadUrlResp.status < 300)) {
                                                                                                      setDownloadLink(downloadUrlResp.data?.url)
                                                                                                  }
                                                                                              })();
                                                                                          }}>Generate Download Link</Button>
                                                                              </Col>
                                                                              : <Col xs={12} md={6}>
                                                                                  <div style={{ fontSize: "10px" }}>Error</div>
                                                                                  <div>{rowData.error}</div>
                                                                              </Col>
                                                                          }
                                                                      </Col>
                                                                  </Row>
                                                                  <Row>
                                                                      <Col xs={12} md={6}>
                                                                          <div style={{ fontSize: "10px" }}>Created</div>
                                                                          <div>{rowData.createdAt}</div>
                                                                      </Col>
                                                                          {(rowData.finishedAt)
                                                                              ?<Col xs={12} md={6}>
                                                                                  <div style={{ fontSize: "10px" }}>Finished</div>
                                                                                  <div>{rowData.finishedAt}</div>
                                                                              </Col>
                                                                              :<Col xs={12} md={6}>
                                                                                  <div style={{ fontSize: "10px" }}>Failed</div>
                                                                                  <div>{rowData.failedAt}</div>
                                                                              </Col>
                                                                          }
                                                                  </Row>
                                                              </Card.Body>
                                                          </Card>
                                                      );
                                                  },
                                              }}
                                              striped={false}
                                              hover={true}
                                              condensed={true}
                                              bordered={false}
                                              bootstrap4={true}/>   
                            : <span>Loading...</span>
                        }
                    </Card.Body>
                    <Modal show={downloadLink != null}
                           onHide={handleCloseLinkModal}>
                        <Modal.Header>
                            <Modal.Title>Download Link</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {(!downloadLink)
                                ? <span>Generating...</span>
                                : <>
                                    <Row className="pb-3">
                                        <Col>Your download link is active for 24 hours. Please download your file within this time, as the link will expire afterwards.</Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <InputGroup>
                                                <FormControl value={downloadLink}
                                                             size="sm"
                                                             readOnly
                                                             style={{
                                                                 paddingRight: "30px"
                                                             }}/>
                                                <Icons icon="copy"
                                                       className=""
                                                       onClick={(event) => {
                                                           event.preventDefault();
                                                           event.stopPropagation();
                                                           try {
                                                               copyToClipboard(downloadLink);
                                                           } catch (err) {
                                                               console.error('Failed to copy URL to clipboard.', err);
                                                           }
                                                       }}
                                                       style={{
                                                           position: "absolute",
                                                           right: "50px",
                                                           top: "10px",
                                                           zIndex: 100
                                                       }}/>
                                                <Button variant="outline-secondary"
                                                        size="sm"
                                                        onClick={() => {
                                                            window.open(downloadLink, '_blank');
                                                        }}>
                                                    <Icons icon="download" />
                                                </Button>
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                </>
                            }
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant={"outline-primary"}
                                    disabled={!downloadLink}
                                    onClick={handleCloseLinkModal}>Close</Button>
                        </Modal.Footer>
                    </Modal>
                </Card>
            </Col>
        </Row>
    );
};

export default BatchOverview;