import {
  Button,
  Card,
  Col,
  Container,
  FloatingLabel,
  Form,
  Modal,
  Nav,
  Row, Tab,
  Table
} from "react-bootstrap";
import { FontAwesomeIcon as Icons } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useRef, useState } from "react";
import axios from "axios";
import { API_URL } from "App";
import { BriActionBar, BriActionBarContainer, BriLockedTag } from "../components/BriActionBar";
import { AuthContext } from "../../context/Context";
import classNames from "classnames";
import { NavLink } from "react-router-dom";
import FalconCloseButton from "../../components/common/FalconCloseButton";
import SoftBadge from "../../components/common/SoftBadge";
import ProcessingModal from "../components/ProcessingModal";

const ProfileEdit = ({
  ...otherProps
}) => {
  const { user } = useContext(AuthContext);

  const [lovMetadata, setLovMetadata] = useState(null);
  const [messageItems, setMessageItems] = useState(null);
  const [messageModalVisible, setMessageModalVisible] = useState(false);
  const [messageModalData, setMessageModalData] = useState({});
  const [firstName, setFirstName] = useState(user.firstName || '');
  const [lastName, setLastName] = useState(user.lastName || '');
  const [confirmPassword, setConfirmPassword] = useState({
    show: false,
    title: "Processing Your Request...",
    message: ["Please wait."]
  });
  const showMessageModal = (messageData) => {
    setMessageModalData(messageData);
    setMessageModalVisible(true);
  }

  useEffect(() => {
    if (lovMetadata == null) {
      (async () => {
        const customerConfigResp = await axios.get(
          `${API_URL}/customer`,
          {
            headers: {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Credentials': 'true',
              'Accept': '*/*',
              'Authorization': `Bearer ${user?.token}`,
              'X-Api-Key': user?.clientKey
            },
            validateStatus: () => true
          }
        );
        if ((200 <= customerConfigResp.status) && (customerConfigResp.status < 300)) {
          setLovMetadata({
            brands: customerConfigResp?.data?.brands?.reduce((result, item) => {
              result[item.id] = item.name;
              return result;
            }, {}),
            campaigns: customerConfigResp?.data?.campaigns?.reduce((result, item) => {
              result[item.id] = item.name;
              return result;
            }, {}),
            allowed: customerConfigResp?.data?.allowedCampaigns?.reduce((result, item) => {
              if (!result[item.brandId]) {
                result[item.brandId] = {};
              }
              if (!result[item.brandId][item.campaignId]) {
                result[item.brandId][item.campaignId] = {};
              }
              result[item.brandId][item.campaignId] = item.messageTypes;
              return result;
            }, {}),
          });
        }
      })();
    }
  }, [lovMetadata, user]);
  useEffect(() => {
    if (messageItems == null) {
      (async () => {
        const customerConfigResp = await axios.get(
          `${API_URL}/build/campaign`,
          {
            headers: {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Credentials': 'true',
              'Accept': '*/*',
              'Authorization': `Bearer ${user?.token}`,
              'X-Api-Key': user?.clientKey,
            },
            validateStatus: () => true,
          }
        );
        if ((200 <= customerConfigResp.status) && (customerConfigResp.status < 300)) {
          setMessageItems(customerConfigResp?.data?.items);

        }
      })();
    }
  }, [messageItems, user]);

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const userData = {
      firstName: firstName,
      lastName: lastName,
      userSub: user.sub,
      role: user.role == 'administrator' ? 'client_administrator' :
      user.role == 'brand_administrator' ? 'client_brand_administrator' :
      'client_user',
      email: user.email,
      clientKey: user.clientKey
    };
      
    try {
      const response = await axios.post(
        `${API_URL}/user`,
        userData,
        {
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Credentials': 'true',
            'Accept': '*/*',
            'Authorization': `Bearer ${user?.token}`,
            'X-Api-Key': user?.clientKey,
          },
          validateStatus: () => true,
        }
      );
  
      if (response.status === 200) {
        // Profile update was successful
        // You can handle success actions here, e.g., show a success message
      } else {
        // Profile update failed
        // You can handle error actions here, e.g., show an error message
      }
    } catch (error) {
      console.error('Error updating profile:', error);
    }
  };

  return (
    <div>
      <Row className="fs--1">
        <Col xs={12}>
          <Card>
            <Card.Header>
              <Row className="align-items-center">
                <Col xs={12}>
                  <h5 className="mb-0">User Profile</h5>
                </Col>
              </Row>
            </Card.Header>
          </Card>
        </Col>
      </Row>
      <Row className="pt-4 fs--1">
        <Col xs={12}>
          <Card>
            <Card.Body>
              <Form onSubmit={handleSubmit}>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm={3}>
                    Email:
                  </Form.Label>
                  <Col sm={9}>
                    <div className="d-flex align-items-center">
                      <Form.Control
                        type="email"
                        value={user.email}
                        readOnly
                        style={{ backgroundColor: '#f0f0f0' }} // Set a background color
                      />
                    </div>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm={3}>
                    First Name:
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      type="text"
                      value={firstName}
                      onChange={(e) => {
                        console.log('First Name:', e.target.value);
                        setFirstName(e.target.value);
                      }}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm={3}>
                    Last Name:
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      type="text"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Col sm={{ span: 9, offset: 3 }}>
                    <Button variant="primary" type="submit">
                      Submit
                    </Button>
                  </Col>
                </Form.Group>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default ProfileEdit;