import Divider from 'components/common/Divider';
import PropTypes from 'prop-types';
import React, {useContext, useEffect, useState} from 'react';
import {Button, Col, Form, Row} from 'react-bootstrap';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';
import SocialAuthButtons from './SocialAuthButtons';
import {AuthContext} from '../../context/Context';
import {setItemToStore, processLogin} from "../../helpers/utils";
import {API_URL} from "../../App";
import axios from "axios";
import ProcessingModal from "../../corectic/components/ProcessingModal";

const LoginForm = ({hasLabel, layout}) => {
    // AuthContext
    const {user, setUser} = useContext(AuthContext);

    const navigate = useNavigate();
    const location = useLocation()

    // State
    const [formData, setFormData] = useState({
        clientId: '',
        email: '',
        password: '',
        remember: false
    });
    const [showProcessingModal, setShowProcessingModal] = useState(false);

    useEffect(() => {
        setFormData({
            ...formData,
            clientId: (new URLSearchParams(location.search))?.get('client')
        });
    }, [location]);

    const handleSubmit = async (e, showProcessingModal, setShowProcessingModal) => {
        e.preventDefault();
        try {
            setShowProcessingModal(true);
            const verifyUserRes = await axios.post(`${API_URL}/verifyUser`,
                {
                    "authSchema": "password",
                    "clientKey": formData.clientId,
                    "username": formData.email,
                    "password": formData.password
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Api-Key': formData.clientId,
                        'Access-Control-Allow-Credentials': 'true',
                        'Accept': '*/*'
                    },
                    validateStatus: () => true
                });

            if ((200 <= verifyUserRes?.status) && (verifyUserRes?.status < 300) && (!!verifyUserRes?.data?.token)) {
                // const appConfig = verifyUserRes?.data?.appConfig || {};
                // setItemToStore(STORAGE_KEY_CLIENT, appConfig?.clientKey);

                processLogin(verifyUserRes?.data?.token, user, setUser);
                navigate('/dashboard');
            }
        } finally {
            setShowProcessingModal(false);
        }
    };

    const handleFieldChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    return (
        <>
            <ProcessingModal show={showProcessingModal}
                             title="Signing In..."
                             message={["Please wait."]}
            />
            <Form onSubmit={(e) => { handleSubmit(e, showProcessingModal, setShowProcessingModal) }}>
                <Form.Group className="mb-3">
                    {hasLabel && <Form.Label>Client Identifier</Form.Label>}
                    <Form.Control
                        placeholder={!hasLabel ? 'Client Identifier' : ''}
                        value={formData.clientId}
                        name="clientId"
                        onChange={handleFieldChange}
                        type="text"
                    />
                </Form.Group>

                <Form.Group className="mb-3">
                    {hasLabel && <Form.Label>Email address</Form.Label>}
                    <Form.Control
                        placeholder={!hasLabel ? 'Email address' : ''}
                        value={formData.email}
                        name="email"
                        onChange={handleFieldChange}
                        type="email" />
                </Form.Group>

                <Form.Group className="mb-3">
                    {hasLabel && <Form.Label>Password</Form.Label>}
                    <Form.Control
                        placeholder={!hasLabel ? 'Password' : ''}
                        value={formData.password}
                        name="password"
                        onChange={handleFieldChange}
                        type="password" />
                </Form.Group>

                <Row className="justify-content-between align-items-center">
                    <Col xs="auto">
                    </Col>

                    <Col xs="auto">
                        <Link
                            className="fs--1 mb-0"
                            to={`/authentication/${layout}/forgot-password`}>
                            Forgot Password?
                        </Link>
                    </Col>
                </Row>

                <Form.Group>
                    <Button
                        type="submit"
                        color="primary"
                        className="mt-3 w-100"
                        disabled={!formData.email || !formData.password || !formData.clientId}
                    >
                        Log in
                    </Button>
                </Form.Group>

                {/*<Divider className="mt-4">or log in with</Divider>*/}

                {/*<SocialAuthButtons />*/}
            </Form></>
    );
};

LoginForm.propTypes = {
    layout: PropTypes.string,
    hasLabel: PropTypes.bool
};

LoginForm.defaultProps = {
    layout: 'simple',
    hasLabel: false
};

export default LoginForm;
