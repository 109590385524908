import React, {useContext, useEffect, useState} from "react";
import {AuthContext, ClientContext} from "../../context/Context";
import { FontAwesomeIcon as Icons } from '@fortawesome/react-fontawesome';
import {Button, Card, Col, Container, FloatingLabel, Form, Modal, Row, Table} from "react-bootstrap";
import FalconCloseButton from "../../components/common/FalconCloseButton";
import axios from "axios";
import {API_URL} from "../../App";
import { v4 as uuid } from 'uuid';

const CampaignModal = ({
                           modalShow,
                           setModalShow,
                           campaignData,
                           lovMetadata,
                           setCampaignData,
                           ...otherProps
}) => {
    const handleClose = () => {
        setModalShow(false);
    }

    return (
        <Modal show={modalShow}
               backdrop="static"
               size="lg"
               onHide={handleClose}>
            <Modal.Header>
                <Modal.Title>{lovMetadata?.campaigns?.[campaignData?.campaignId] ?? "Create New Campaign"}</Modal.Title>
                <FalconCloseButton onClick={handleClose}/>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={async (e) => {
                          e.preventDefault()

                          const formData = Object.fromEntries((new FormData(e.target)).entries());
                        
                          await setCampaignData?.({
                              "@sourceData": campaignData,
                              brandId: formData.campaignBrand || campaignData?.brandId,
                              ...((formData.campaignTitle)
                                  ? { campaign: formData.campaignTitle }
                                  : { campaignId: campaignData?.campaignId }),
                              messageTypes: {
                                  email: !!formData.campaignMessageTypeEmail,
                                  sms: !!formData.campaignMessageTypeSMS,
                                  desc: !!formData.campaignMessageTypeDesc,
                              },
                          });
                      }}>
                    <Row>
                        <Col xs={5}>
                            <FloatingLabel controlId="campaignBrand"
                                           label="Brand">
                                <Form.Select name="campaignBrand" size="sm"
                                             required
                                             disabled={!!campaignData?.brandId}
                                             defaultValue={campaignData?.brandId}>
                                    <option value=""
                                            selected
                                            disabled>(Select Brand)</option>
                                    {Object.entries(lovMetadata?.brands || {}).map(([brandId, brandName]) => (
                                        <option value={brandId}>{brandName}</option>
                                    ))}
                                </Form.Select>
                            </FloatingLabel>
                        </Col>
                        <Col xs={7}>
                            <FloatingLabel controlId="campaignTitle"
                                           label="Campaign Title">
                                <Form.Control name="campaignTitle" size="sm"
                                              type="text"
                                              required
                                              disabled={!!campaignData?.campaignId}
                                              placeholder="Campaign Title"
                                              defaultValue={lovMetadata?.campaigns?.[campaignData?.campaignId]} />
                                {/*<Form.Text muted={true}>"Campaign Title" purpose description...</Form.Text>*/}
                            </FloatingLabel>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col xs={4}>
                            <Form.Check id='campaignMessageTypeEmail'
                                        name='campaignMessageTypeEmail'
                                        type='checkbox'
                                        label='Email'
                                        defaultChecked={campaignData?.messageTypes?.email} />
                        </Col>
                        <Col xs={4}>
                            <Form.Check id='campaignMessageTypeSMS'
                                        name='campaignMessageTypeSMS'
                                        type='checkbox'
                                        label='SMS'
                                        defaultChecked={campaignData?.messageTypes?.sms} />
                        </Col>
                        <Col xs={4}>
                            <Form.Check id='campaignMessageTypeDesc'
                                        name='campaignMessageTypeDesc'
                                        type='checkbox'
                                        label='Description'
                                        defaultChecked={campaignData?.messageTypes?.desc} />
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Col xs={6} className="d-flex justify-content-start">
                            <Button variant="primary" type="submit" size="sm">Save&nbsp;Changes</Button>
                        </Col>
                        <Col xs={6} className="d-flex justify-content-end">
                             {campaignData?.campaignId && <Button type="submit" value="delete" variant="link" size="sm"
                             onClick={async () => {
                                await setCampaignData?.({
                                    "@sourceData": campaignData,
                                    "action": "delete"
                                });
                             }}
                             >Delete</Button>}
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-start">
                <Button variant="link"
                        onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

const Actions = ({
                     rowData,
                     editActionHandler,
                     ...otherProps
}) => {
    return (
        <div className="end-0 pe-3 hover-actions"
             style={{marginTop: "-5px"}}>
            <Button variant="light"
                    size="sm"
                    className="border-300 me-1 text-600"
                    onClick={(e) => {
                        e.preventDefault();
                        editActionHandler(rowData, e);
                    }}>
                <Icons icon="edit" />
            </Button>
        </div>
    );
};

const TableRow = ({
                      rowData,
                      lovMetadata,
                      editActionHandler,
                      ...otherProps
}) => {

    return (
        <tr className="hover-actions-trigger">
            <td>{lovMetadata?.brands?.[rowData?.brandId]}</td>
            <td>{lovMetadata?.campaigns?.[rowData?.campaignId]}</td>
            <td><Form.Check type="checkbox"
                            checked={rowData?.messageTypes?.email}
                            disabled /></td>
            <td><Form.Check type="checkbox"
                            checked={rowData?.messageTypes?.sms}
                            disabled /></td>
            <td><Form.Check type="checkbox"
                            checked={rowData?.messageTypes?.desc}
                            disabled /></td>
            <td className="w-auto">
                <Actions rowData={rowData}
                         editActionHandler={editActionHandler} />
            </td>
        </tr>
    );
};

const CampaignManager = ({
                             ...otherProps
}) => {
    const { user } = useContext(AuthContext);
    const {clientConfig, setClientConfig } = useContext(ClientContext);

    const [lovMetadata, setLovMetadata] = useState(null);
    const [campaignItems, setCampaignItems] = useState(null);
    const [campaignModalVisible, setCampaignModalVisible] = useState(false);
    const [campaignModalData, setCampaignModalData] = useState({});

    const showCampaignModal = (campaignData) => {
        console.log(campaignData);        
        setCampaignModalData(campaignData);
        setCampaignModalVisible(true);
    }

    useEffect(() => {
        if ((campaignItems == null) || (lovMetadata == null)) {
            setCampaignItems(clientConfig?.allowedCampaigns);
            setLovMetadata({
                brands: clientConfig?.brands?.reduce((result, item) => {
                    result[item.id] = item.name;
                    return result;
                }, {}),
                campaigns: clientConfig?.campaigns?.reduce((result, item) => {
                    result[item.id] = item.name;
                    return result;
                }, {}),
            });
        }
    }, campaignItems, lovMetadata, clientConfig);

    return (
        <Row className="pt-3">
            <Col xs={12}>
                <Card>
                    <Card.Body className="bg-light">
                        <Button variant="primary"
                                className="position-absolute"
                                onClick={(event) => {
                                    showCampaignModal({});
                                }}
                                size="sm"
                                style={{
                                    right: "1.25rem",
                                    top: "-5.5rem"
                                }}>Create New Campaign</Button>
                        <CampaignModal modalShow={campaignModalVisible}
                                       setModalShow={setCampaignModalVisible}
                                       campaignData={campaignModalData}
                                       lovMetadata={lovMetadata}
                                       setCampaignData={async (newCampaignData) => {
                                           const updateRequest = {};
                                           if (newCampaignData["action"] == "delete") {                                            
                                            campaignItems.splice(campaignModalData.id, 1)
                                            updateRequest.allowedCampaigns = campaignItems;
                                           }
                                           else if (newCampaignData["@sourceData"]?.brandId && newCampaignData["@sourceData"]?.campaignId) {
                                               campaignItems.forEach((campaignItem) => {
                                                   if((campaignItem.brandId == newCampaignData["@sourceData"]?.brandId) && (campaignItem.campaignId == newCampaignData["@sourceData"]?.campaignId)) {
                                                       campaignItem.messageTypes = newCampaignData.messageTypes;
                                                   }
                                               });
                                               updateRequest.allowedCampaigns = campaignItems;
                                           } else if (!newCampaignData["@sourceData"]?.campaignId) {                                            
                                               const newCampaignId = uuid();
                                               updateRequest.campaigns = Object.entries(lovMetadata.campaigns).map(([campaignId, campaignName]) => ({
                                                   id: campaignId,
                                                   name: campaignName,
                                               }));
                                               updateRequest.campaigns.push({
                                                   id: newCampaignId,
                                                   name: newCampaignData.campaign,
                                               });

                                               updateRequest.allowedCampaigns = [...campaignItems, {
                                                   brandId: newCampaignData.brandId,
                                                   campaignId: newCampaignId,
                                                   messageTypes: newCampaignData.messageTypes
                                               }];
                                           } else {
                                               // TODO:
                                           }

                                           const customerConfigResp = await axios.put(
                                               `${API_URL}/customer`,
                                               updateRequest,
                                               {
                                                   headers: {
                                                       'Content-Type': 'application/json',
                                                       'Access-Control-Allow-Credentials': 'true',
                                                       'Accept': '*/*',
                                                       'Authorization': `Bearer ${user?.token}`,
                                                       'X-Api-Key': user?.clientKey
                                                   },
                                                   validateStatus: () => true
                                               }
                                           );
                                           if ((200 <= customerConfigResp.status) && (customerConfigResp.status < 300)) {
                                               setClientConfig(null);
                                               setCampaignItems(null);
                                               setLovMetadata(null);
                                               setCampaignModalVisible(false);
                                           }
                                       }}/>
                        <Table hover
                               responsive
                               striped
                               className="mt-3 fs--1">
                            <thead>
                                <tr className="bg-300">
                                    <th scope="col">Brand</th>
                                    <th scope="col">Campaign</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">SMS</th>
                                    <th scope="col">Desc.</th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {(campaignItems)
                                    ? campaignItems.map((campaign, key) => (
                                        <TableRow rowData={campaign}
                                                  lovMetadata={lovMetadata}
                                                  editActionHandler={() => {
                                                      showCampaignModal({...campaign, id: key});
                                                  }} />))
                                    : (
                                        <tr>
                                            <td colSpan="*">Loading...</td>
                                        </tr>)}
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    )
}

export default CampaignManager;