import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, {useContext} from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import team3 from 'assets/img/team/3.jpg';
import Avatar from 'components/common/Avatar';
import {AuthContext} from "../../../context/Context";

const ProfileDropdown = () => {
    const { user } = useContext(AuthContext);

  return (
    <Dropdown navbar={true} as="li">
      <Dropdown.Toggle
        bsPrefix="toggle"
        as={Link}
        to="#!"
        className="pe-0 ps-2 nav-link"
      >
          {(user)
              ? (<Avatar name={`${user.firstName?.[0]}${user.lastName?.[0]}`} />)
              : (<Avatar />)
          }
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-caret dropdown-menu-card  dropdown-menu-end">
        <div className="bg-white rounded-2 py-2 dark__bg-1000">
          <Dropdown.Item as={Link} to="/user/profile">My Profile</Dropdown.Item>
            <Dropdown.Item as={Link} to="/authentication/simple/logout">Logout</Dropdown.Item>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProfileDropdown;
