export const dashboardRoutes = {
    label: 'Dashboard',
    labelDisable: true,
    children: [
        {
            name: 'Dashboard',
            active: true,
            icon: 'home',
            to: '/dashboard',
            exact: true,
        },
    ],
};

export const recommendationRoutes = {
    label: 'Recommendations',
    labelDisable: true,
    children: [
        {
            name: 'Recommendations',
            active: true,
            icon: 'bullseye',
            to: '/recommendations',
            exact: false,
        },
    ],
};

export const messageMakerRoutes = {
    label: 'Message Maker',
    labelDisable: true,
    children: [
        {
            name: 'Message Maker',
            active: true,
            icon: 'envelope',
            to: '/message-maker',
            exact: false,
        },
    ],
};

export const scoringRoutes = {
    label: 'Scoring',
    labelDisable: true,
    children: [
        {
            name: 'Scoring',
            active: true,
            icon: 'users',
            children: [
                {
                    name: "Manual Uploads",
                    active: true,
                    to: '/scoring/batch',
                    exact: true,
                },
                {
                    name: "Synchronisation",
                    active: true,
                    to: '/scoring/auto-sync',
                    exact: true,
                },
                {
                    name: "Online Validator",
                    active: true,
                    to: '/scoring/online-validator',
                    exact: true,
                },
            ],
        },
    ],
};

export const settingRoutes = {
    label: '------',
    labelDisable: false,
    children: [
        {
            name: 'Settings',
            active: true,
            icon: 'screwdriver-wrench',
            children: [
                {
                    name: 'Campaigns',
                    active: true,
                    to: '/settings/campaigns',
                    exact: true,
                },
                {
                    name: 'Users',
                    active: true,
                    to: '/settings/users',
                    exact: true,
                },
                {
                    name: 'Mappings',
                    active: true,
                    to: '/settings/mappings',
                    exact: true,
                },
                {
                    name: 'Client Setup',
                    active: true,
                    to: '/settings/client-setup',
                    exact: true,
                },

            ]
        },
    ]
};

export default [
    dashboardRoutes,
    recommendationRoutes,
    messageMakerRoutes,
    scoringRoutes,
    settingRoutes,
];
