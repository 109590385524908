import {
    Button,
    Card,
    Col,
    Form,
    InputGroup,
    Image,
    Row,
} from "react-bootstrap";
import React, { useContext, useEffect, useRef, useState } from "react";
import { AuthContext, ClientContext } from "../../context/Context";
import Flex from "../../components/common/Flex";
import { FontAwesomeIcon as Icons, FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { API_URL } from "../../App";
import { useDropzone } from "react-dropzone";
// import defaultLogo from "../../assets/corectic/images/logo.svg";
import { v4 as uuid } from "uuid";
import ProcessingModal from "../components/ProcessingModal";
import logoCorectic from 'assets/corectic/images/logo-corectic.svg';
import logoMyndrive from 'assets/corectic/images/logo-myndrive.png';

const defaultLogo = (["console.myndrive.com"].includes(window.location.hostname)) ? logoMyndrive : logoCorectic;

const ClientSetup = ({
    ...otherProps
}) => {
    const { user } = useContext(AuthContext);
    const { clientConfig, setClientConfig } = useContext(ClientContext);
    
    const [showProcessingModal, setShowProcessingModal] = useState({
        show: false,
        title: "Processing Your Request...",
        message: ["Please wait."]
    });
    const [logoFile, setLogoFile] = useState(null);
    const [headerBackground, setHeaderBackground] = useState("initial");
    const [navigationBackground, setNavigationBackground] = useState("initial");
    const [editingBrandId, setEditingBrandId] = useState(null);
    const [currentBrandId, setCurrentBrandId] = useState(null);
    const [brandNameInput, setBrandNameInput] = useState('');
    const [newBrand, setNewBrand] = useState(false);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop: async (acceptedFiles) => {
            setLogoFile({
                ...(acceptedFiles?.[0] || {}),
                rawDataPromise: getFileData(acceptedFiles?.[0]),
                preview: URL.createObjectURL(acceptedFiles?.[0])
            });
        }
    });

    useEffect(() => {
        setHeaderBackground(clientConfig?.headerBackground || "initial");
        setNavigationBackground(clientConfig?.navigationBackground || "initial");
    }, [clientConfig]);

    const getFileData = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result); // log the base64 encoded string to console
            reader.onerror = (error) => reject(error);
            reader.readAsDataURL(file);
        });
    }

    return (
        <Row className="pt-3">
            <Col xs={12}>
                <Card>
                    <Card.Body>
                        <ProcessingModal show={showProcessingModal?.show}
                            title={showProcessingModal?.title}
                            message={showProcessingModal?.message} />
                        <Form onSubmit={async (e) => {
                            e.preventDefault();

                            try {
                                setShowProcessingModal({
                                    show: true,
                                    title: "Storing Data...",
                                    message: ["Please wait."]
                                });

                                const customerConfigResp = await axios.put(
                                    `${API_URL}/customer`,
                                    {
                                        headerBackground: headerBackground,
                                        navigationBackground: navigationBackground,
                                        logoIconData: await logoFile?.rawDataPromise,
                                        brands: clientConfig.brands
                                    },
                                    {
                                        headers: {
                                            'Content-Type': 'application/json',
                                            'Access-Control-Allow-Credentials': 'true',
                                            'Accept': '*/*',
                                            'Authorization': `Bearer ${user?.token}`,
                                            'X-Api-Key': user?.clientKey
                                        },
                                        validateStatus: () => true
                                    }
                                );
                                if ((200 <= customerConfigResp.status) && (customerConfigResp.status < 300)) {
                                    setClientConfig(null);
                                }

                            } finally {
                                setShowProcessingModal({ show: false });
                            }
                        }}>
                            <Row className="pt-3 fs--1">
                                <Col xs={12} md={6}>
                                    <h6>Brands</h6>
                                    <Row className="border-bottom border-300 py-2 px-1 mx-0 align-items-center bg-300">
                                        <Col xs={12} sm={6} md={8} xl={9} xxl={10}>
                                            <span className="fw-bold">Brand</span>
                                        </Col>
                                        <Col xs={12} sm={6} md={4} xl={3} xxl={2}
                                            className="ms-auto align-self-start">
                                            <span className="fw-bold"></span>
                                        </Col>
                                    </Row>
                                    {clientConfig?.brands?.map?.((brand, index) =>
                                    (<Row key={brand?.id}
                                        onClick={(e) => {
                                            e.preventDefault();
                                        }}
                                        className="border-bottom border-200 hover-actions-trigger hover-shadow py-2 px-1 mx-0 align-items-center">
                                        <Col xs={12} md={8} xl={9} xxl={10}>
                                            {editingBrandId === brand?.id ? (
                                                <input
                                                    type="text"
                                                    defaultValue={brand?.name}
                                                    onChange={(e) => {
                                                        setBrandNameInput(e.target.value)
                                                    }}
                                                />
                                            ) : (
                                                <span className="fw-bold">{brand?.name}</span>
                                            )}
                                        </Col>
                                        <Col xs={12} md={4} xl={3} xxl={2}
                                            as={Flex}
                                            // direction="column"
                                            justifyContent="end"
                                            className="">
                                            <div className="hover-actions end-0 top-50 pe-0 translate-middle-y">
                                                <Button variant="light"
                                                    size="sm"
                                                    className="border-300 me-1 text-600"
                                                    onClick={async (e) => {
                                                        e.preventDefault();
                                                        if (editingBrandId === brand?.id) {
                                                            setCurrentBrandId(brand.id);


                                                            clientConfig.brands = clientConfig.brands.map((br) =>
                                                                brand.id === br.id ? { ...br, name: brandNameInput } : br
                                                            );

                                                            setEditingBrandId(null); // End editing mode
                                                        } else {
                                                            setEditingBrandId(brand?.id); // Start editing mode
                                                        }
                                                    }}>
                                                    {editingBrandId === brand.id ? <Icons icon="check" /> : <Icons icon="edit" />}
                                                </Button>
                                                <Button variant="light"
                                                    size="sm"
                                                    className="border-300 me-1 text-600"
                                                    onClick={async (e) => {
                                                        e.preventDefault();
                                                        clientConfig.brands = clientConfig?.brands.filter(br => br.id != brand.id);
                                                        clientConfig.allowedCampaigns = clientConfig?.allowedCampaigns.filter(camp => camp.brandId != brand.id);

                                                        const customerConfigResp = await axios.put(
                                                            `${API_URL}/customer`,
                                                            {
                                                                brands: clientConfig.brands,
                                                                allowedCampaigns: clientConfig.allowedCampaigns
                                                            },
                                                            {
                                                                headers: {
                                                                    'Content-Type': 'application/json',
                                                                    'Access-Control-Allow-Credentials': 'true',
                                                                    'Accept': '*/*',
                                                                    'Authorization': `Bearer ${user?.token}`,
                                                                    'X-Api-Key': user?.clientKey
                                                                },
                                                                validateStatus: () => true
                                                            }
                                                        );
                                                        if ((200 <= customerConfigResp.status) && (customerConfigResp.status < 300)) {
                                                            setClientConfig(null);
                                                        }

                                                    }}>
                                                    <Icons icon="trash-alt" />
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row>))
                                    }
                                    {(newBrand) 
                                    ? <Row key='newBrand'
                                    onClick={(e) => {
                                        e.preventDefault();
                                    }}
                                    className="border-bottom border-200 hover-actions-trigger hover-shadow py-2 px-1 mx-0 align-items-center">
                                    <Col xs={12} md={8} xl={9} xxl={10}>
                                        {
                                            <input
                                                type="text"
                                                onChange={(e) => {
                                                    setBrandNameInput(e.target.value)
                                                }}
                                            />
                                        }
                                    </Col>
                                    <Col xs={12} md={4} xl={3} xxl={2}
                                        as={Flex}
                                        // direction="column"
                                        justifyContent="end"
                                        className="">
                                        <div className="hover-actions end-0 top-50 pe-0 translate-middle-y">
                                            <Button variant="light"
                                                size="sm"
                                                className="border-300 me-1 text-600"
                                                onClick={async (e) => {
                                                    e.preventDefault();
                                                    const createdBrand = {
                                                        id : brandNameInput.toLowerCase(),
                                                        name : brandNameInput
                                                    }
                                                    clientConfig?.brands.push(createdBrand);
                                                    setNewBrand(false);
                                                }}>
                                                <Icons icon="check" />
                                            </Button>
                                            <Button variant="light"
                                                size="sm"
                                                className="border-300 me-1 text-600"
                                                onClick={async (e) => {
                                                    e.preventDefault();
                                                    setNewBrand(false);
                                                }}>
                                                <Icons icon="trash-alt" />
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                                    : <span></span>}
                                    <Row className="pt-2">
                                        <Col xs={12}>
                                            <Button variant="light"
                                                size="sm"
                                                className="border-300 me-1 text-600"
                                                onClick={(e) => {
                                                    e.preventDefault();

                                                    setNewBrand(true)
                                                }}>Add Brand</Button>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={12} md={6}>
                                    <h6></h6>
                                    <Row className="py-2 px-1 mx-0 align-items-center">
                                        <Col xs={12}>
                                            <Row className="py-1 px-1">
                                                <InputGroup className="flex-center">
                                                    <Col xs={4}>
                                                        <Flex justifyContent="center"
                                                            alignContent="center"
                                                            style={{
                                                                backgroundColor: headerBackground,
                                                            }}>
                                                            <Image id="logoImage"
                                                                max-width={180}
                                                                height={35}
                                                                src={logoFile?.preview || clientConfig?.logoIconData || defaultLogo} alt={logoFile?.path} />
                                                        </Flex>
                                                    </Col>
                                                    <Col xs={8}>
                                                        <Flex {...getRootProps({ className: 'dropzone-area p-0' })}>
                                                            <input {...getInputProps({ multiple: false })} />
                                                            <Flex justifyContent="start">
                                                                <FontAwesomeIcon icon="cloud-upload-alt"
                                                                    alt="Upload"
                                                                    width={25}
                                                                    className="me-2 my-auto" />
                                                                <p className="my-auto fs-0 text-700">{logoFile ? logoFile?.path : "Drop your file here."} </p>
                                                            </Flex>
                                                        </Flex>
                                                    </Col>
                                                </InputGroup>
                                            </Row>
                                            <Row className="py-1 px-1">
                                                <InputGroup className="flex-center">
                                                    <Col xs={4}>
                                                        <Form.Control type="color"
                                                            className="w-100"
                                                            id="headerBackground"
                                                            onChange={(e) => {
                                                                e.preventDefault();
                                                                setHeaderBackground(e.target.value);
                                                            }}
                                                            value={headerBackground}
                                                            title="Header Background" />
                                                    </Col>
                                                    <Col xs={8}>
                                                        <Form.Label htmlFor="headerBackground"
                                                            className="ms-2 my-1"
                                                            role="button">Header Background</Form.Label>
                                                    </Col>
                                                </InputGroup>
                                            </Row>
                                            <Row className="py-1 px-1">
                                                <InputGroup className="flex-center">
                                                    <Col xs={4}>
                                                        <Form.Control type="color"
                                                            className="w-100"
                                                            id="navigationBackground"
                                                            onChange={(e) => {
                                                                e.preventDefault();
                                                                setNavigationBackground(e.target.value);
                                                            }}
                                                            value={navigationBackground}
                                                            title="Navigation Background" />
                                                    </Col>
                                                    <Col xs={8}>
                                                        <Form.Label htmlFor="navigationBackground"
                                                            className="ms-2 my-1"
                                                            role="button">Navigation Background</Form.Label>
                                                    </Col>
                                                </InputGroup>
                                            </Row>
                                            {/*<Row className="py-1 px-1">*/}
                                            {/*    <InputGroup className="flex-center">*/}
                                            {/*        <Col xs={4}*/}
                                            {/*             as={Flex}*/}
                                            {/*             className="justify-content-center align-content-center">*/}
                                            {/*            <Form.Switch id="dataUsageConsent"*/}
                                            {/*                         defaultChecked={clientConfig?.dataUsageConsent?.consent}*/}
                                            {/*                         role="button"*/}
                                            {/*                         title="Data Usage Consent" />*/}
                                            {/*        </Col>*/}
                                            {/*        <Col xs={8}>*/}
                                            {/*            <Form.Label className="ms-2 my-1">Client Consent Source Data Usage</Form.Label>*/}
                                            {/*        </Col>*/}
                                            {/*    </InputGroup>*/}
                                            {/*</Row>*/}
                                            <Row className="py-1 px-1">
                                                <Col xs={4}></Col>
                                                <Col xs={8}
                                                    as={Flex}
                                                    className="justify-content-left align-content-center">
                                                    <Button variant="light"
                                                        size="sm"
                                                        className="border-300 me-1 text-600"
                                                        onClick={(e) => {
                                                            e.preventDefault();

                                                            setLogoFile("");
                                                            document.getElementById('logoImage').src = defaultLogo;
                                                            setHeaderBackground('initial');
                                                            document.getElementById('headerBackground').value = null;
                                                            setNavigationBackground('initial');
                                                            document.getElementById('navigationBackground').value = null;

                                                        }}>Reset&nbsp;Theme</Button>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="pt-3 fs--1">
                                <Col xs={12} className="d-flex justify-content-start">
                                    <Button variant="primary" type="submit" size="sm">Save&nbsp;Changes</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
};

export default ClientSetup;