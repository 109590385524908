import { Card, Col, Container, Form, Row } from "react-bootstrap";
import React, { useContext, useEffect, useRef, useState } from "react";
import {AuthContext, ClientContext} from "../../context/Context";
import Select from "react-select";
import { startOfWeek, endOfWeek, startOfMonth, endOfMonth, startOfYear, endOfYear, format, parseISO, parse, sub } from 'date-fns'
import { useForm } from 'react-hook-form';

import ReactEChartsCore from "echarts-for-react/lib/core";
import * as echarts from 'echarts/core';
import { BarChart } from 'echarts/charts';
import {
    GridComponent,
    TooltipComponent,
    TitleComponent,
    LegendComponent
} from 'echarts/components';

import { CanvasRenderer } from 'echarts/renderers';
import { getPosition, tooltipFormatter } from 'helpers/echart-utils';
import { getColor, rgbaColor } from 'helpers/utils';
import moment from "moment";
import axios from "axios";
import { API_URL } from "../../App";

import ProcessingModal from "../components/ProcessingModal";
import classNames from "classnames";
import Flex from "../../components/common/Flex";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as DOMPurify from 'dompurify';
import {NavLink} from "react-router-dom";

/**
 * lambda function to build array of dates in format dd-mm-yyyy ending yesterday and starting defined days ago.
 */
const generateDates = (days) => {
    const dates = [];
    for (let i = days; i > 0; i--) {
        dates.push(moment().subtract(i, 'days').format('DD-MMM-YYYY'));
    }
    return dates;
};

const Dashboard = ({
    ...otherProps
}) => {
    const { user } = useContext(AuthContext);
    const { clientConfig } = useContext(ClientContext);

    const contactsChartRef = useRef(null);
    const groupsChartRef = useRef(null);
    const messagesChartRef = useRef(null);

    const [lovBrands, setLovBrands] = useState(null);

    const [recommendationItems, setRecommendationItems] = useState(null);
    const [recommendationModal, setRecommendationModal] = useState({
        showModal: false,
        recommendation: null,
    });
    const [showProcessingModal, setShowProcessingModal] = useState({
        show: false,
        title: "Processing Your Request...",
        message: ["Please wait."]
    });
    const [statisticsData, setStatisticsData] = useState(null);

    const [formData, setFormData] = useState({
        brand: null,
        date: null
    });

    const [filter, setFilter] = useState({
        brandIds: [],
        dateFilter: null,
    });

    const timeFilter = [
        { value: 'THIS_WEEK', label: 'This week'},
        { value: 'LAST_WEEK', label: 'Last week'},
        { value: 'LAST_7_DAYS', label: 'Last 7 days'},
        { value: 'LAST_28_DAYS', label: 'Last 28 days'},
        { value: 'LAST_12_MONTHS', label: 'Last 12 months'},
        { value: 'THIS_YEAR', label: 'This calendar year'}
    ];

    const defaultValues = {
        timeZone: 'GMT-12:00/Etc/GMT-12'
      };
      const submittedValues = {};
      const { register, handleSubmit, setValue, reset } = useForm({
        defaultValues
      });
    
      const handleChange = (name, value) => {
        setFormData({
          ...formData,
          [name]: value
        });
      };

    echarts.use([
        TitleComponent,
        TooltipComponent,
        GridComponent,
        BarChart,
        CanvasRenderer,
        LegendComponent
    ]);

    //converts date format dd/MM/yyyy to dd-MMM-yyyy
    const convertDateFormat = (inputDate) => {
        let parts = inputDate.split(inputDate);
    }

    const handleDateFilter = (type, dateToCheck) => {        
        if(type === "THIS_WEEK") {
            let date = new Date();
            let from = startOfWeek(date);
            let to = endOfWeek(date);
            
            setFilter({
                ...filter, 
                dateFilter: {
                    from: format(from, "dd-MMM-yyyy"),
                    to: format(to, "dd-MMM-yyyy")
                }
            });            
        }
        
        if(type === "LAST_WEEK") {
            let date = sub(new Date(), {
                weeks: 1
            });
            let from = startOfWeek(date);
            let to = endOfWeek(date);
            
            setFilter({
                ...filter, 
                dateFilter: {
                    from: format(from, "dd-MMM-yyyy"),
                    to: format(to, "dd-MMM-yyyy")
                }
            });
        }

        if(type === "LAST_7_DAYS") {
            let to = new Date();
            let from = sub(to, {                
                days: 7
            });            
            
            setFilter({
                ...filter, 
                dateFilter: {
                    from: format(from, "dd-MMM-yyyy"),
                    to: format(to, "dd-MMM-yyyy")
                }
            });
        }

        if(type === "LAST_28_DAYS") {
            let to = new Date();
            let from = sub(to, {                
                days: 28
            });            
            
            setFilter({
                ...filter, 
                dateFilter: {
                    from: format(from, "dd-MMM-yyyy"),
                    to: format(to, "dd-MMM-yyyy")
                }
            });
        }        

        if(type === "LAST_12_MONTHS") {
            let today = new Date();
            
            setFilter({
                ...filter, 
                dateFilter: {
                    to: format(today, "dd-MMM-yyyy"),
                    from: format(today.setMonth(today.getMonth() - 12), "dd-MMM-yyyy")
                }
            });
        }
        
        if(type === "THIS_YEAR") {
            let date = new Date();            
            let from = startOfYear(date);
            let to = endOfYear(date);
            
            setFilter({
                ...filter, 
                dateFilter: {
                    from: format(from, "dd-MMM-yyyy"),
                    to: format(to, "dd-MMM-yyyy")
                }
            });
        }


    }

    const updateDimensions = () => {
        if (window.innerWidth < 530) {
            groupsChartRef.current.getEchartsInstance().setOption({
                series: [{ radius: '45%' }]
            });
        }
        else
            groupsChartRef.current.getEchartsInstance().setOption({
                series: [{ radius: '60%' }]
            });
    };

    useEffect(() => {
        if (lovBrands == null) {
            setLovBrands(clientConfig?.brands?.map((item) => ({ value: item.id, label: item.name })));
        }
    }, [lovBrands, clientConfig]);

    useEffect(() => {
        if (!recommendationItems) {
            (async () => {
                const recommendationsResp = await axios.get(
                    `${API_URL}/recommendation`,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Access-Control-Allow-Credentials': 'true',
                            'Accept': '*/*',
                            'Authorization': `Bearer ${user?.token}`,
                            'X-Api-Key': user?.clientKey
                        },
                        validateStatus: () => true
                    }
                );
                if ((200 <= recommendationsResp.status) && (recommendationsResp.status < 300)) {
                    setRecommendationItems([recommendationsResp?.data?.items[0]] || []);
                }
            })();
        }
    }, [recommendationItems, user]);

    useEffect(() => {
        if (!statisticsData) {
            (async () => {
                // let params = (filter.dateFilter)? `?from=${filter.dateFilter.from}&to=${filter.dateFilter.to}` : '';
                
                const coreStatisticsResp = await axios.get(
                    `${API_URL}/statistics/core`,
                    {
                        params: {
                            from: filter?.dateFilter?.from,
                            to: filter?.dateFilter?.to,
                        },
                        headers: {
                            'Content-Type': 'application/json',
                            'Access-Control-Allow-Credentials': 'true',
                            'Accept': '*/*',
                            'Authorization': `Bearer ${user?.token}`,
                            'X-Api-Key': user?.clientKey
                        },
                        validateStatus: () => true
                    }
                );
                if ((200 <= coreStatisticsResp.status) && (coreStatisticsResp.status < 300)) {
                    const stats = {
                        summary: {
                            cCount: 0,
                            oCount: 0,
                            rCount: 0,
                            eCount: 0,
                            totalCount: 0,
                        },
                        daily: {
                            days: [],
                            cCount: [],
                            oCount: [],
                            rCount: [],
                            eCount: [],
                            totalCount: [],
                        },
                    };
                    coreStatisticsResp?.data?.items?.forEach?.((item) => {
                    // if(filter.dateFilter && !dateFilterCheck(filter.dateFilter, item.processingTime)){
                    //     return;
                    // } 
                        stats.summary.cCount += item.cCount;
                        stats.summary.oCount += item.oCount;
                        stats.summary.rCount += item.rCount;
                        stats.summary.eCount += item.eCount;
                        stats.summary.totalCount += item.totalCount;
                        stats.daily.days.push(item.processingTime);
                        stats.daily.cCount.push(item.cCount);
                        stats.daily.oCount.push(item.oCount);
                        stats.daily.rCount.push(item.rCount);
                        stats.daily.eCount.push(item.eCount);
                        stats.daily.totalCount.push(item.totalCount);
                    });
                    setStatisticsData(stats);
                }
            })();
        }
    }, [statisticsData, user]);

    useEffect(() => {
        window.addEventListener('resize', updateDimensions);
        return () => window.removeEventListener('resize', updateDimensions);
    }, []);

    return (
        <div>
            <Row className="g-3 mb-3 fs--1">
                <Col xs={12}>
                    <Card>
                        <Card.Header>
                            <Row className="align-items-center">
                                <Col xs={12} sm={5}>
                                    <h5 className="mb-0">Dashboard</h5>
                                </Col>
                                <Col xs={{ offset: 0, span: 6 }}
                                    sm={{ offset: 0, span: 3 }}
                                    md={{ offset: 0, span: 4 }}
                                    className="">
                                    <Select isMulti={true}
                                        options={lovBrands}
                                        placeholder='(All Brands)'
                                        closeMenuOnSelect={false}
                                        defaultValue=""
                                        classNamePrefix="react-select"
                                        onChange={(target) => {                                            
                                            setFilter({...filter, brandIds: target.map(sBrand => sBrand['value'])});                                            
                                          }}>
                                    </Select>
                                </Col>
                                <Col xs={{ offset: 0, span: 6 }}
                                    sm={{ offset: 0, span: 3 }}
                                    md={{ offset: 0, span: 3 }}
                                    className="">                                    
                                    <Select isMulti={false}
                                        options={timeFilter}
                                        placeholder='Date Range'
                                        closeMenuOnSelect={false}
                                        defaultValue=""
                                        classNamePrefix="react-select"
                                        onChange={(target) => {
                                            setStatisticsData(null);
                                            handleDateFilter(target.value);
                                            //setFilter({...filter, dateFilter: target.value});
                                            }}>
                                    </Select>
                                </Col>
                            </Row>
                        </Card.Header>
                    </Card>
                </Col>
            </Row>
            <Row className="g-3 mb-3 fs--1">
                <Col xs={12}
                    md={6}>
                    <Card>
                        <Card.Header>
                            <Row>
                                <Col xs={12}
                                    className="d-flex justify-content-between">
                                    <h5>Contacts</h5>
                                    <em>Total per Group</em>
                                </Col>
                            </Row>
                        </Card.Header>
                        <Card.Body className="bg-light">
                            <Row>
                                <Col xs={12}>
                                    <ReactEChartsCore echarts={echarts}
                                        option={{
                                            tooltip: {
                                                trigger: 'axis',
                                                padding: [7, 10],
                                                backgroundColor: getColor('gray-100'),
                                                borderColor: getColor('gray-300'),
                                                textStyle: getColor('dark'),
                                                borderWidth: 1,
                                                formatter: tooltipFormatter,
                                                transitionDuration: 0,
                                                axisPointer: {
                                                    type: 'line'
                                                }
                                            },
                                            xAxis: {
                                                type: 'category',
                                                data: statisticsData?.daily?.days,
                                                boundaryGap: false,
                                                axisLine: {
                                                    lineStyle: {
                                                        color: getColor('gray-400')
                                                    }
                                                },
                                                axisTick: { show: true },
                                                axisLabel: {
                                                    color: getColor('gray-600'),
                                                    //formatter: value => value.substring(0, 3),
                                                    margin: 15
                                                },
                                                splitLine: {
                                                    show: false
                                                }
                                            },
                                            yAxis: {
                                                type: 'value',
                                                splitLine: {
                                                    lineStyle: {
                                                        color: getColor('gray-200')
                                                    }
                                                },
                                                boundaryGap: false,
                                                axisLabel: {
                                                    show: true,
                                                    color: getColor('gray-600'),
                                                    margin: 15
                                                },
                                                axisTick: { show: false },
                                                axisLine: { show: false }
                                            },
                                            series: [
                                                {
                                                    name: 'C',
                                                    type: 'line',
                                                    stack: 'Total',
                                                    symbolSize: 10,
                                                    itemStyle: {
                                                        color: getColor('white'),
                                                        borderColor: getColor('bri-core-c'),
                                                        borderWidth: 2
                                                    },
                                                    lineStyle: {
                                                        color: getColor('bri-core-c')
                                                    },
                                                    symbol: 'circle',
                                                    data: statisticsData?.daily?.cCount,
                                                    areaStyle: {
                                                        color: rgbaColor(getColor('bri-core-c'), 0.3)
                                                    }
                                                },
                                                {
                                                    name: 'O',
                                                    type: 'line',
                                                    stack: 'Total',
                                                    symbolSize: 10,
                                                    itemStyle: {
                                                        color: getColor('white'),
                                                        borderColor: getColor('bri-core-o'),
                                                        borderWidth: 2
                                                    },
                                                    lineStyle: {
                                                        color: getColor('bri-core-o')
                                                    },
                                                    symbol: 'circle',
                                                    data: statisticsData?.daily?.oCount,
                                                    areaStyle: {
                                                        color: rgbaColor(getColor('bri-core-o'), 0.3)
                                                    }
                                                },
                                                {
                                                    name: 'R',
                                                    type: 'line',
                                                    stack: 'Total',
                                                    symbolSize: 10,
                                                    itemStyle: {
                                                        color: getColor('white'),
                                                        borderColor: getColor('bri-core-r'),
                                                        borderWidth: 2
                                                    },
                                                    lineStyle: {
                                                        color: getColor('bri-core-r')
                                                    },
                                                    symbol: 'circle',
                                                    data: statisticsData?.daily?.rCount,
                                                    areaStyle: {
                                                        color: rgbaColor(getColor('bri-core-r'), 0.3)
                                                    }
                                                },
                                                {
                                                    name: 'E',
                                                    type: 'line',
                                                    stack: 'Total',
                                                    symbolSize: 10,
                                                    itemStyle: {
                                                        color: getColor('white'),
                                                        borderColor: getColor('bri-core-e'),
                                                        borderWidth: 2
                                                    },
                                                    lineStyle: {
                                                        color: getColor('bri-core-e')
                                                    },
                                                    symbol: 'circle',
                                                    data: statisticsData?.daily?.eCount,
                                                    areaStyle: {
                                                        color: rgbaColor(getColor('bri-core-e'), 0.3)
                                                    }
                                                },
                                            ],
                                            grid: { right: 40, left: 5, bottom: 5, top: 8, containLabel: true }
                                        }}
                                        style={{ height: '18.75rem' }}
                                        ref={contactsChartRef} />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12}
                    md={6}>
                    <Card>
                        <Card.Header>
                            <Row>
                                <Col xs={12}
                                    className="d-flex justify-content-between">
                                    <h5>Recommendations</h5>
                                    <em></em>
                                </Col>
                            </Row>
                        </Card.Header>
                        <Card.Body className="bg-light" style={{ height: '17.55rem', overflow: 'hidden' }}>
                            <Row>
                                <Col xs={12}>
                                    <ProcessingModal show={showProcessingModal?.show}
                                        title={showProcessingModal?.title}
                                        message={showProcessingModal?.message} />
                                    {recommendationItems
                                        ? recommendationItems.length > 0
                                            ? recommendationItems?.map((recommendation, index) => (
                                                ((filter.brandIds.includes(recommendation.brandId)) || filter.brandIds.length === 0) &&
                                                <Row key={recommendation.id}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setRecommendationModal({
                                                            showModal: true,
                                                            recommendation: recommendation,
                                                        });
                                                    }}
                                                    className={classNames(
                                                        'hover-shadow py-2 px-1 mx-0 align-items-center'
                                                    )}>                                                    
                                                    <Col md={12} xl={12} xxl={12}>
                                                        <Row>
                                                            <Col>                                                                
                                                                <Row className="py-2">
                                                                    <Col sm="8" md="6" xl="8">
                                                                        <NavLink className="nav-link-text" 
                                                                            style={{color: "var(--falcon-gray-700)", 
                                                                            fontFamily: "var(--falcon-font-sans-serif)", fontWeight: "500", fontSize: "14px"}}
                                                                            to={`/recommendations/${recommendation.id}`}>{recommendation?.title}</NavLink>
                                                                    </Col>
                                                                    <Col sm="4" md="6" xl="4">
                                                                        <p style={{display:'flex', justifyContent:'right',
                                                                            color: "var(--falcon-gray-700)", 
                                                                            fontFamily: "var(--falcon-font-sans-serif)", 
                                                                            fontSize: "12.5px"}} className={classNames('mb-2', 'text-600')}>
                                                                                {format(parse(recommendation.date, 'dd/MM/yyyy', new Date()), "dd-MMM-yyyy")}
                                                                        </p>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col md={12} xl={12} xxl={12}>
                                                        <Row>
                                                            <Col>
                                                                <div className="dashboard-recommendations" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(recommendation?.content) }} />
                                                                <Row className="py-2">
                                                                    <Col sm="8" md="6" xl="8"></Col>
                                                                    <Col sm="4" md="6" xl="4" style={{display:'flex', justifyContent:'right'}}>
                                                                        <NavLink className="nav-link-text" style={{color: "var(--falcon-gray-700)", 
                                                                            fontFamily: "var(--falcon-font-sans-serif)", fontWeight: "500", fontSize: "14px"}}
                                                                            to={`/recommendations/${recommendation.id}`}>Read more...</NavLink>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>))
                                            : <div className="text-center py-3">No recommendations available.</div>

                                        : (<div className="text-center py-3">Loading...</div>)
                                    }
                                </Col>
                            </Row>
                        </Card.Body>
                        <Card.Footer>
                            <NavLink to="/recommendations" className="nav-link-text" 
                                style={{color: "var(--falcon-gray-700)", fontFamily: "var(--falcon-font-sans-serif)", fontWeight: "500", fontSize: "14px"}}>
                                    More Recommendations
                            </NavLink>
                        </Card.Footer>
                    </Card>
                </Col>
            </Row>
            <Row className="g-3 mb-3 fs--1">
                <Col xs={12}
                    md={6}>
                    <Card>
                        <Card.Header>
                            <Row>
                                <Col xs={12}
                                    className="d-flex justify-content-between">
                                    <h5>Groups</h5>
                                    <em>Split per Group</em>
                                </Col>
                            </Row>
                        </Card.Header>
                        <Card.Body className="bg-light">
                            <Row>
                                <Col xs={12}>
                                    <ReactEChartsCore echarts={echarts}
                                        option={{
                                            legend: {
                                                left: 'left',
                                                textStyle: {
                                                    color: getColor('gray-600')
                                                }
                                            },
                                            series: [
                                                {
                                                    type: 'pie',
                                                    radius: window.innerWidth < 530 ? '45%' : '60%',
                                                    label: {
                                                        color: getColor('gray-700')
                                                    },
                                                    center: ['50%', '55%'],
                                                    data: [
                                                        {
                                                            value: statisticsData?.summary?.cCount,
                                                            name: 'C',
                                                            itemStyle: {
                                                                color: getColor('bri-core-c')
                                                            }
                                                        },
                                                        {
                                                            value: statisticsData?.summary?.oCount,
                                                            name: 'O',
                                                            itemStyle: {
                                                                color: getColor('bri-core-o')
                                                            }
                                                        },
                                                        {
                                                            value: statisticsData?.summary?.rCount,
                                                            name: 'R',
                                                            itemStyle: {
                                                                color: getColor('bri-core-r')
                                                            }
                                                        },
                                                        {
                                                            value: statisticsData?.summary?.eCount,
                                                            name: 'E',
                                                            itemStyle: {
                                                                color: getColor('bri-core-e')
                                                            }
                                                        }
                                                    ],
                                                    emphasis: {
                                                        itemStyle: {
                                                            shadowBlur: 10,
                                                            shadowOffsetX: 0,
                                                            shadowColor: rgbaColor(getColor('gray-600'), 0.5)
                                                        }
                                                    }
                                                }
                                            ],
                                            tooltip: {
                                                trigger: 'item',
                                                padding: [7, 10],
                                                backgroundColor: getColor('gray-100'),
                                                borderColor: getColor('gray-300'),
                                                textStyle: { color: getColor('dark') },
                                                borderWidth: 1,
                                                transitionDuration: 0,
                                                axisPointer: {
                                                    type: 'none'
                                                }
                                            }
                                        }}
                                        style={{ height: '18.75rem' }}
                                        ref={groupsChartRef} />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12}
                    md={6}>
                    <Card>
                        <Card.Header>
                            <Row>
                                <Col xs={12}
                                    className="d-flex justify-content-between">
                                    <h5>Messages</h5>
                                    <em>Active v Completed</em>
                                </Col>
                            </Row>
                        </Card.Header>
                        <Card.Body className="bg-light">
                            <Row>
                                <Col xs={12}>
                                    <ReactEChartsCore echarts={echarts}
                                        option={{
                                            color: [
                                                getColor('success'),
                                                getColor('danger'),
                                            ],
                                            legend: {
                                                data: [
                                                    'Completed',
                                                    'In Progress'
                                                ],
                                                textStyle: {
                                                    color: getColor('gray-700')
                                                },
                                                left: 0
                                            },
                                            tooltip: {
                                                trigger: 'axis',
                                                padding: [7, 10],
                                                backgroundColor: getColor('gray-100'),
                                                borderColor: getColor('gray-300'),
                                                textStyle: getColor('dark'),
                                                borderWidth: 1,
                                                transitionDuration: 0,
                                                axisPointer: {
                                                    type: 'shadow'
                                                }
                                            },
                                            xAxis: {
                                                data: generateDates(7),
                                                splitLine: { show: false },
                                                splitArea: { show: false },
                                                axisLabel: {
                                                    color: getColor('gray-600')
                                                },

                                                axisLine: {
                                                    lineStyle: {
                                                        color: getColor('gray-400')
                                                    }
                                                }
                                            },
                                            yAxis: {
                                                splitLine: {
                                                    lineStyle: {
                                                        color: getColor('gray-200')
                                                    }
                                                },
                                                axisLabel: {
                                                    color: getColor('gray-600')
                                                }
                                            },
                                            series: [
                                                {
                                                    name: 'Completed',
                                                    type: 'bar',
                                                    stack: 'one',
                                                    emphasis: {
                                                        itemStyle: {
                                                            shadowBlur: 10,
                                                            shadowColor: rgbaColor(getColor('dark'), 0.3)
                                                        }
                                                    },
                                                    data: [5, 8, 12, 8, 14, 14, 14]
                                                },
                                                {
                                                    name: 'In Progress',
                                                    type: 'bar',
                                                    stack: 'one',
                                                    emphasis: {
                                                        itemStyle: {
                                                            shadowBlur: 10,
                                                            shadowColor: rgbaColor(getColor('dark'), 0.3)
                                                        }
                                                    },
                                                    data: [15, 12, 8, 17, 11, 11, 11]
                                                }
                                            ],
                                            grid: {
                                                top: '10%',
                                                bottom: 10,
                                                left: 5,
                                                right: 7,
                                                containLabel: true
                                            }
                                        }}
                                        style={{ height: '18.75rem' }}
                                        ref={messagesChartRef} />

                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row className="g-3 mb-3 fs--1">
                <Col xs={12}
                    md={6}>
                    <Card>
                        <Card.Header>
                            <Row>
                                <Col xs={12}
                                    className="d-flex justify-content-between">
                                    <h5>Synchronization Errors</h5>
                                </Col>
                            </Row>
                        </Card.Header>
                        <Card.Body className="bg-light">
                            <Row>
                                <Col xs={12}>
                                    <ReactEChartsCore echarts={echarts}
                                        option={{
                                            legend: {
                                                data: [
                                                    // 'Success',
                                                    'Failure'
                                                ],
                                                textStyle: {
                                                    color: getColor('gray-700')
                                                },
                                                left: 0
                                            },
                                            tooltip: {
                                                trigger: 'axis',
                                                padding: [7, 10],
                                                backgroundColor: getColor('gray-100'),
                                                borderColor: getColor('gray-300'),
                                                textStyle: { color: getColor('dark') },
                                                borderWidth: 1,
                                                formatter: tooltipFormatter,
                                                transitionDuration: 0,
                                                axisPointer: {
                                                    type: 'shadow'
                                                }
                                            },
                                            xAxis: {
                                                type: 'category',
                                                data: generateDates(7),
                                                axisLine: {
                                                    lineStyle: {
                                                        color: getColor('gray-400'),
                                                    }
                                                },
                                                axisTick: { show: true },
                                                axisLabel: {
                                                    color: getColor('gray-600'),
                                                    // formatter: value => value.substring(0, 3),
                                                    margin: 15
                                                },
                                                splitLine: {
                                                    show: false
                                                }
                                            },
                                            yAxis: {
                                                type: 'value',
                                                axisLabel: {
                                                    show: true,
                                                    color: getColor('gray-600'),
                                                    margin: 15
                                                },
                                                splitLine: {
                                                    show: true,
                                                    lineStyle: {
                                                        color: getColor('gray-200')
                                                    }
                                                },
                                                axisTick: { show: false },
                                                axisLine: { show: false },
                                                max: 24
                                            },
                                            series: [
                                                // {
                                                //     type: 'bar',
                                                //     name: 'Success',
                                                //     data: [24, 24, 24, 22, 24, 21, 24],
                                                //     lineStyle: { color: getColor('success') },
                                                //     itemStyle: {
                                                //         color: getColor('success'),
                                                //         borderRadius: [3, 3, 0, 0]
                                                //     },
                                                //     showSymbol: false,
                                                //     symbol: 'circle',
                                                //     smooth: false,
                                                //     emphasis: {
                                                //         scale: true
                                                //     }
                                                // },
                                                {
                                                    type: 'bar',
                                                    name: 'Failure',
                                                    label: {
                                                        show: true,
                                                        position: 'top',
                                                        color: getColor('danger'),
                                                        formatter: ({ value }) => value ? value : "",
                                                    },

                                                    data: [0, 0, 0, 2, 0, 3, 0],
                                                    lineStyle: { color: getColor('danger') },
                                                    itemStyle: {
                                                        color: getColor('danger'),
                                                        borderRadius: [3, 3, 0, 0]
                                                    },
                                                    showSymbol: false,
                                                    symbol: 'circle',
                                                    smooth: false,
                                                    emphasis: {
                                                        scale: true
                                                    }
                                                }
                                            ],
                                            grid: { right: '3%', left: '10%', bottom: '10%', top: '10%' }
                                        }}
                                        style={{ height: '18.75rem' }} />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default Dashboard;