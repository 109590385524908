import { Badge, Button, Card, Col, Container, FloatingLabel, Form, Modal, Nav, Row } from "react-bootstrap";
import React, { useContext, useEffect, useRef, useState } from "react";
import { AuthContext, ClientContext } from "../../context/Context";
import { useParams } from "react-router-dom";
import classNames from "classnames";
import Flex from "../../components/common/Flex";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FalconCloseButton from "../../components/common/FalconCloseButton";
import * as DOMPurify from 'dompurify';
import axios from "axios";
import { API_URL } from "../../App";
import TinymceEditor from "../../components/common/TinymceEditor";
import ProcessingModal from "../components/ProcessingModal";

const MessageViewModal = ({
    show,
    itemData,
    lovMetadata,
    handleClose,
    ...otherProps
}) => {
    return (
        <Modal show={show}
            backdrop="static"
            size="xl"
            onHide={handleClose}>
            <Modal.Header>
                <Modal.Title>{itemData?.title}</Modal.Title>
                <FalconCloseButton onClick={handleClose} />
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col xs={12} md={6}
                        className=""><span>Brand:&nbsp;</span>{lovMetadata?.brands?.[itemData?.brandId]}</Col>
                    <Col xs={12} md={6}
                        className="text-start text-md-end"><span>Date:&nbsp;</span>{itemData?.date}</Col>
                </Row>
                <Row className="pt-4">
                    <Col xs={12}>
                        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(itemData?.content) }} />
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-start">
                <Button variant="link"
                    onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

const MessageEditModal = ({
    show,
    itemData,
    setItemData,
    lovMetadata,
    setShowProcessingModal,
    handleClose,
    setRecommendationModal,
    ...otherProps
}) => {
    const [recommendationContent, setRecommendationContent] = useState(null);
    const brandRef = useRef();
    const titleRef = useRef();

    const { user } = useContext(AuthContext);

    useEffect(() => {
        setRecommendationContent(itemData?.content || "");
    }, [itemData]);

    const [isDeleting, setIsDeleting] = useState(false);

    const handleDeleteClick = async () => {
        setShowProcessingModal({
            show: true,
            title: "Deleting...",
            message: ["Please wait."]
        });
        if (isDeleting) {
          return;
        }
    
        setIsDeleting(true);

        try {
            const updateRocemmendationResp = await axios.delete(`${API_URL}/recommendation/${itemData.id}`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Credentials': 'true',
                        'Accept': '*/*',
                        'Authorization': `Bearer ${user?.token}`,
                        'X-Api-Key': user?.clientKey
                    },
                    validateStatus: () => true
                });
            if ((200 <= updateRocemmendationResp.status) && (updateRocemmendationResp.status < 300)) {
                location.reload();
            }
        
        } catch (err) {
            console.error(err);
        } finally {
            setIsDeleting(false);
            setShowProcessingModal({ show: false });
        }

      };

    return (
        <Modal show={show}
            backdrop="static"
            size="xl"
            onHide={handleClose}>
            <Modal.Header>
                <div className="p-2 flex-grow-1 fw-bold">{itemData?.title || 'New recommendation'}</div>
                <div className="p-2 fw-bold"><span className="float-end">{itemData?.date ? `Date: ${itemData?.date}` : ''}</span></div>
                <FalconCloseButton onClick={handleClose} />
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={async (e) => {
                    e.preventDefault();

                    try {
                        setShowProcessingModal({
                            show: true,
                            title: "Storing Data...",
                            message: ["Please wait."]
                        });
                        await setItemData({
                            id: itemData?.id,
                            brandId: brandRef?.current?.value,
                            title: titleRef?.current?.value,
                            content: recommendationContent
                        });
                    } catch (err) {
                        console.error(err);
                    } finally {
                        setShowProcessingModal({ show: false });
                    }
                }}>
                    <Row>
                        <Col xs={12} md={6}
                            className="">
                            <FloatingLabel controlId="recommendationBrand"
                                label="Brand">
                                <Form.Select size="sm"
                                    name="recommendationBrand"
                                    required
                                    defaultValue={itemData?.brandId || ""}
                                    ref={brandRef}>
                                    <option value=""
                                        disabled>(Select Brand)</option>
                                    {Object.entries(lovMetadata?.brands || {}).map(([brandId, brandName]) => (
                                        <option key={`brand-edit-${brandId}`}
                                            value={brandId}>{brandName}</option>
                                    ))}
                                </Form.Select>
                                {/*<Form.Text muted={true}>"Brand" purpose description...</Form.Text>*/}
                            </FloatingLabel>
                        </Col>
                        <Col xs={12} md={6}
                            className="">
                            <FloatingLabel controlId="recommendationTitle"
                                label="Recommendation Summary">
                                <Form.Control size="sm"
                                    name="recommendationTitle"
                                    type="text"
                                    required
                                    placeholder="Recommendation Summary"
                                    defaultValue={itemData?.title}
                                    ref={titleRef} />
                                {/*<Form.Text muted={true}>"Message" purpose description...</Form.Text>*/}
                            </FloatingLabel>
                        </Col>
                    </Row>
                    <Row className="pt-3">
                        <Col xs={12}>
                            <TinymceEditor
                                value={recommendationContent}
                                handleChange={(content) => {
                                    setRecommendationContent(content);
                                }}
                                tinymceProps={{
                                    toolbar: 'undo redo | fontsize forecolor backcolor | bold italic | alignleft aligncenter alignright alignjustify outdent indent | bullist numlist | link image table',
                                    plugins: "link image lists table media directionality visualblocks",
                                }} />
                        </Col>
                    </Row>
                    <Row className="pt-3">
                        <Col xs={6} className="d-flex justify-content-start">
                            <Button variant="primary" size="sm"
                                type="submit">Save&nbsp;All&nbsp;Changes</Button>
                        </Col>
                        <Col xs={6} className="d-flex justify-content-end">
                            <Button
                                variant="link"
                                size="sm"
                                onClick={handleDeleteClick}
                                disabled={isDeleting} >
                                {itemData?.title ? "Delete" : ''}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-start">
                <Button variant="link"
                    onClick={async (e) => {
                        e.preventDefault();

                        try {
                            setShowProcessingModal({
                                show: true,
                                title: "Marking as read...",
                                message: ["Please wait."]
                            });
                            await setItemData({
                                id: itemData?.id,
                                brandId: brandRef?.current?.value,
                                title: titleRef?.current?.value,
                                content: recommendationContent,
                                read: true
                            });
                        } catch (err) {
                            console.error(err);
                        } finally {
                            setShowProcessingModal({ show: false });
                        }
                    }}
                >
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

const Remmendations = ({
    underConstruction,
    ...otherProps
}) => {
    let { id } = useParams();
    const { user } = useContext(AuthContext);
    const { clientConfig } = useContext(ClientContext);

    const [lovMetadata, setLovMetadata] = useState(null);
    const [recommendationItems, setRecommendationItems] = useState(null);
    const [recommendationModal, setRecommendationModal] = useState({
        showModal: false,
        recommendation: null,
        viewedRecommendation: false
    });
    const [showProcessingModal, setShowProcessingModal] = useState({
        show: false,
        title: "Processing Your Request...",
        message: ["Please wait."]
    });

    useEffect(() => {
        if (lovMetadata == null && clientConfig != null) {
            setLovMetadata({
                brands: clientConfig?.brands?.reduce((result, item) => {
                    result[item.id] = item.name;
                    return result;
                }, {}),
                campaigns: clientConfig?.campaigns?.reduce?.((result, item) => {
                    result[item.id] = item.name;
                    return result;
                }, {}),
                allowed: clientConfig?.allowedCampaigns?.reduce((result, item) => {
                    if (!result[item.brandId]) {
                        result[item.brandId] = {};
                    }
                    if (!result[item.brandId][item.campaignId]) {
                        result[item.brandId][item.campaignId] = {};
                    }
                    result[item.brandId][item.campaignId] = item.messageTypes;
                    return result;
                }, {}),
            });
        }
    }, [lovMetadata, clientConfig]);

    useEffect(() => {
        if (!recommendationItems) {
            (async () => {
                const recommendationsResp = await axios.get(
                    `${API_URL}/recommendation`,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Access-Control-Allow-Credentials': 'true',
                            'Accept': '*/*',
                            'Authorization': `Bearer ${user?.token}`,
                            'X-Api-Key': user?.clientKey
                        },
                        validateStatus: () => true
                    }
                );
                if ((200 <= recommendationsResp.status) && (recommendationsResp.status < 300)) {
                    setRecommendationItems(recommendationsResp?.data?.items || []);
                }
            })();
        }
    }, [recommendationItems, user]);

    useEffect(() => {
        if (id !== undefined && recommendationModal.viewedRecommendation == false 
                && recommendationItems !== null && lovMetadata !== null && clientConfig != null) {
            setRecommendationModal({                            
                showModal: true,
                viewedRecommendation: true,
                recommendation: recommendationItems.find(recommendations => recommendations.id === id)
            });
        }
    }, [id, recommendationItems, lovMetadata, clientConfig]);

    return (
        <div>
            <Row>
                <Col xs={12}>
                    <Card>
                        <Card.Header>
                            <Row className="align-items-center">
                                <Col xs={12}>
                                    <h5 className="mb-0">Recommendations</h5>
                                </Col>
                            </Row>
                        </Card.Header>
                    </Card>
                </Col>
            </Row>
            <Row className="pt-3 fs--1">
                <Col xs={12}>
                    <Card>
                        <Card.Body>
                            {(user?.role === "administrator")
                                ? (<>
                                    <Button variant="primary"
                                        className="position-absolute"
                                        size="sm"
                                        onClick={(event) => {
                                            setRecommendationModal({ showModal: true, recommendation: {} });
                                        }}
                                        style={{
                                            right: "1.25rem",
                                            top: "-3.6rem"
                                        }}>Create New</Button>
                                    <MessageEditModal show={recommendationModal?.showModal}
                                        itemData={recommendationModal.recommendation}
                                        setItemData={async (newItemData) => {                                            
                                            const updateRecommendationResp = await axios.put(`${API_URL}/recommendation`,
                                                newItemData,
                                                {
                                                    headers: {
                                                        'Content-Type': 'application/json',
                                                        'Access-Control-Allow-Credentials': 'true',
                                                        'Accept': '*/*',
                                                        'Authorization': `Bearer ${user?.token}`,
                                                        'X-Api-Key': user?.clientKey
                                                    },
                                                    validateStatus: () => true
                                                });
                                            if ((200 <= updateRecommendationResp.status) && (updateRecommendationResp.status < 300)) {
                                                setRecommendationItems(null); // ~ reload recommendation items
                                                setRecommendationModal({ showModal: false });
                                            }
                                        }}
                                        lovMetadata={lovMetadata}
                                        setShowProcessingModal={setShowProcessingModal}
                                        handleClose={() => {
                                            setRecommendationModal({ showModal: false, });
                                        }} />
                                </>)
                                : (<MessageViewModal show={recommendationModal?.showModal}
                                    itemData={recommendationModal.recommendation}
                                    lovMetadata={lovMetadata}
                                    handleClose={() => {
                                        setRecommendationItems(null); // ~ reload recommendation items
                                        setRecommendationModal({ showModal: false, });

                                    }} />)
                            }
                            <ProcessingModal show={showProcessingModal?.show}
                                title={showProcessingModal?.title}
                                message={showProcessingModal?.message} />
                            <Row className={classNames('border-bottom border-200 hover-actions-trigger hover-shadow py-2 px-1 mx-0 align-items-center bg-light')}>
                                <Col xs={2} as={Flex} direction="column" justifyContent="between" className="me-auto align-self-start"><span className="fw-bold">Date</span></Col>
                                <Col md={8} xl={9} xxl={8}>
                                    <Row>
                                        <Col xs={12} md={5} xl={4} xxl={3}>
                                            <span className="fw-bold">Brand</span>
                                        </Col>
                                        <Col xs={12} md={5} xl={4} xxl={4}>
                                            <span className="fw-bold">Recommendation</span>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs="auto" as={Flex} direction="column" justifyContent="between" className="ms-auto align-self-start"><span className="fw-bold">Read</span></Col>
                            </Row>
                            {recommendationItems
                                ? recommendationItems?.map((recommendation, index) => (
                                    <Row key={recommendation.id}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setRecommendationModal({
                                                showModal: true,
                                                recommendation: recommendation,
                                            });
                                        }}
                                        className={classNames(
                                            'border-bottom border-200 hover-actions-trigger hover-shadow py-2 px-1 mx-0 align-items-center',
                                            {
                                                'bg-light': recommendation.read
                                            }
                                        )}>
                                        <Col xs={2}
                                            as={Flex}
                                            direction="column"
                                            justifyContent="between"
                                            className="me-auto align-self-start">
                                            <span className={classNames({ 'fw-bold': !recommendation?.read })}>{recommendation.date}</span>
                                        </Col>
                                        <Col md={8} xl={9} xxl={8}>
                                            <Row>
                                                <Col xs={4} md={4} xl={4} xxl={3}>
                                                    <span className={classNames({ 'fw-bold': !recommendation?.read })}>{lovMetadata?.brands?.[recommendation?.brandId]}</span>
                                                </Col>
                                                <Col xs={4} md={4} xl={4} xxl={4}>
                                                    <span className={classNames({ 'fw-bold': !recommendation?.read })}>{recommendation?.title}</span>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs="auto"
                                            as={Flex}
                                            direction="column"
                                            justifyContent="between"
                                            className="ms-auto align-self-start">
                                            {recommendation?.read
                                                ? (<FontAwesomeIcon icon='eye' />)
                                                : (<FontAwesomeIcon icon='eye-slash' />)}
                                        </Col>
                                    </Row>))
                                : (<div className="text-center py-3">Loading...</div>)
                            }
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default Remmendations;