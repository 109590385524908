import {Button, Card, Col, Container, FloatingLabel, Form, Modal, Nav, Navbar, OverlayTrigger, Row, Spinner, Tooltip} from 'react-bootstrap';
import React, {useContext, useEffect, useState} from 'react';
import axios from 'axios';
import {FontAwesomeIcon as Icons} from '@fortawesome/react-fontawesome';
import {API_URL, CORE_API_URL} from 'App';
import {AuthContext} from '../../context/Context';

const OnlineValidator = ({
                             ...otherProps
                         }) => {
    const {user} = useContext(AuthContext);

    const [fieldItems, setFieldItems] = useState(null);
    const [scoreResult, setScoreResult] = useState(null);
    const [callingService, setCallingService] = useState(false);

    const handleCloseResultModal = () => {
        setScoreResult(null);
    };

    useEffect(() => {
        if (fieldItems == null) {
            (async () => {
                const fieldsResp = await axios.get(
                    `${API_URL}/customer/fields`,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Access-Control-Allow-Credentials': 'true',
                            'Accept': '*/*',
                            'Authorization': `Bearer ${user?.token}`,
                            'X-Api-Key': user?.clientKey
                        },
                        validateStatus: () => true
                    }
                );
                if ((200 <= fieldsResp.status) && (fieldsResp.status < 300)) {
                    setFieldItems(fieldsResp?.data?.customerFields);
                }
            })();
        }
    }, [fieldItems, user]);

    return (
        <Row className="pt-3 fs--1">
            <Col>
                <Card className="mx-auto w-auto">
                    <Form className=""
                          onSubmit={(event) => {
                              event.preventDefault();

                              setCallingService(true);

                              const request = fieldItems.reduce((result, field) => {
                                  result[field.apiParam || field.code] = event.target[field.code]?.value;
                                  return result;
                              }, {});

                              (async () => {
                                  try {
                                      const result = await axios.post(
                                          `${CORE_API_URL}/score`,
                                          request,
                                          {
                                              headers: {
                                                  'Access-Control-Allow-Credentials': 'true',
                                                  'Accept': '*/*',
                                                  'X-Api-Key': user?.clientKey
                                              }
                                          }
                                      );
                                      setScoreResult(result);
                                  } finally {
                                      setCallingService(false);
                                  }
                              })();
                          }}>
                        <Card.Title className="p-3">
                            <Navbar className="w-100">
                                <Nav className="me-auto">
                                    <OverlayTrigger overlay={<Tooltip style={{position: "fixed"}}>Clear form values.</Tooltip>}
                                                    placement="right"
                                                    delay={{show: 500, hide: 200}}>
                                        <Button type="reset" size="sm"
                                                variant="outline-secondary"><Icons icon="arrows" /></Button>
                                    </OverlayTrigger>
                                </Nav>
                                <Navbar.Text>Online Service Validator</Navbar.Text>
                                <Nav className="ms-auto">
                                    <OverlayTrigger overlay={<Tooltip style={{position: "fixed"}}>Call service API.</Tooltip>}
                                                    placement="right"
                                                    delay={{show: 500, hide: 200}}>
                                        <Button type="submit" size="sm"
                                                disabled={callingService}
                                                variant="outline-primary">{(callingService) ? <Spinner size="sm"/> : "Call"}</Button>
                                    </OverlayTrigger>
                                </Nav>
                            </Navbar>
                        </Card.Title>
                        <Card.Body className='bg-light'>
                            {(fieldItems)
                                ? <>
                                    {fieldItems.map((field) => {
                                        return (
                                            <Row key={`corectic-field-${field.code}`}
                                                 className="mb-2">
                                                <Col xs={12}>
                                                    <FloatingLabel controlId={field.code}
                                                                   className=""
                                                                   size="sm"
                                                                   label={field.apiParam || field.code}>
                                                        <Form.Control type="text" size="sm"
                                                                      placeholder={field.apiParam || field.code}/>
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>
                                        );
                                    })}
                                </>
                                : <span>Loading...</span>}
                        </Card.Body>
                    </Form>
                </Card>
                <Modal show={scoreResult}
                       onHide={handleCloseResultModal}>
                    <Modal.Header>
                        <Modal.Title>Scoring Result</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="mb-2">
                            <Col xs={12}>
                                <FloatingLabel controlId="result-field-status"
                                               className=""
                                               label="HTTP Response Status">
                                    <Form.Control type="text"
                                                  readOnly={true}
                                                  value={scoreResult?.status}
                                                  placeholder="HTTP Response Status"/>
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col xs={12}>
                                <FloatingLabel controlId="result-field-score"
                                               className=""
                                               label="Calculated Type">
                                    <Form.Control type="text"
                                                  readOnly={true}
                                                  value={scoreResult?.data?.coreType}
                                                  placeholder="HTTP Response Status"/>
                                </FloatingLabel>
                            </Col>
                        </Row>
                        {user?.role === "administrator"
                            ? <Row className="mb-2">
                                <Col xs={12}>
                                    <pre>{JSON.stringify(scoreResult?.data, null, 2)}</pre>
                                </Col>
                            </Row>
                            : <></>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={handleCloseResultModal}>Close</Button>
                    </Modal.Footer>
                </Modal>
            </Col>
        </Row>
    );
};

export default OnlineValidator;