import React from "react";
import {Button} from "react-bootstrap";
import { FontAwesomeIcon as Icons } from '@fortawesome/react-fontawesome';

import "./BriActionBar.css";

export const BriActionBarContainer = ({
                                          as,
                                          children,
                                          ...otherProps
}) => {
    const As = as;
    return (
        <As {...otherProps}
            className={["bri-action-bar-container", otherProps?.className].join(' ')}>
            {children}
        </As>
    );
};

export const BriActionBar = ({
                                 children,
                                 ...otherProps
}) => {
    return (
        <div className={["bri-action-bar-commands", otherProps?.className].join(' ')}>
            {children}
        </div>
    );
};

export const BriLockedTag = ({
                                 ...otherProps
}) => {
    return (
        <>
            <Icons icon="lock" className={["bri-action-bar-lock-tag", otherProps?.className].join(' ')} />
            <Icons icon="unlock" className={["bri-action-bar-unlock-tag", otherProps?.className].join(' ')} />
        </>
    );
}

const BriActionBarItem = ({
                              clickHandler,
                              icon,
                              ...otherProps
}) => {
    return (
        <Button variant="outline-secondary"
                className="py-0 mx-0"
                size="sm"
                {...otherProps}
                onClick={(event) => {
                    event.stopPropagation();
                    event.preventDefault();
                    clickHandler?.(event);
                }}>
            <Icons icon={icon || "pencil-alt"} />

        </Button>
    );
};
BriActionBar.Item = BriActionBarItem;